<template>
    <!-- <div id="trade" class="trade">
        <div class="trade-content">
            <h2 >智慧财务-企业间在线交易结算</h2>
            <div class="trade-content-tab">
                <div :class="['trade-content-tab-item',{active:item.isActive}]"
                v-for="(item,index) in tabs"
                :key="index"
                @click="handleClick(item)"
                >{{item.name}}</div>
            </div>
            <div class="trade-content-box">
                <component :is="currentTabCmp"></component>
            </div>
        </div>
    </div> -->
    <div id="trade" class="trade">
        <div class="content">
            <h2 class="daohan">智慧财务-企业间在线交易结算</h2>
            <!-- <div class="trade-content-box">
                <component :is="currentTabCmp"></component>
            </div> -->
            <TradeMode></TradeMode>
            <TradeSolution></TradeSolution>
            <TradeTransform></TradeTransform>
        </div>
    </div>
</template>

<script>
import TradeMode from './TradeMode.vue'
import TradeSolution from './TradeSolution.vue'
import TradeTransform from './TradeTransform.vue'
export default {
    components:{
        TradeMode,
        TradeSolution,
        TradeTransform
    },
    data () {
        return {
            // tabs:[
            //     {type:'TradeMode',isActive:true,name:'传统模式在线交易的弊端'},
            //     {type:'TradeSolution',isActive:false,name:'慧财的解决方案'},
            //     {type:'TradeTransform',isActive:false,name:'带来的变革'}
            // ],
            // lastAcitve:{},
            // currentTabCmp: 'TradeMode'
        }
    },
    methods: {
        // handleClick(item){
        //     this.currentTabCmp = item.type
        //     this.lastAcitve.isActive = false
        //     item.isActive = !item.isActive
        //     this.lastAcitve = item
        // }
    },
    mounted(){
        // this.lastAcitve = this.tabs[0]
    }
}
</script>

<style lang="scss" scoped>
.trade{
    width:100%;
    // height:1000px;
    background:#fff;
    .content{
        width: 80%;
        margin: 0 auto;
        .daohan{
            font-size: 36px;
            height: 75px;
            border-bottom:1px solid #999999 ;
        }
    }
    // &-content{
    //     width: 1200px;
    //     margin: 0 auto;
    //     padding-top: 80px;
    //     h2{
    //         text-align: center;
    //         margin: 0 0 70px 0;
    //         font-size: 30px;
    //     }
    //     &-tab{
    //         display: flex;
    //         width: 100%;
    //         &-item{
    //             flex: 1;
    //             // width:400px;
    //             height:120px;
    //             background:rgba(255,255,255,1);
    //             box-shadow:0px 0px 6px 0px rgba(51,51,51,0.11);
    //             text-align: center;
    //             line-height: 120px;
    //             font-size:22px;
    //             font-family:'Source Han Sans CN';
    //             font-weight:bold;
    //             color:rgba(51,51,51,1);
    //             cursor: pointer;
    //             &.active{
    //                 color: #fff;
    //                 background:rgba(44,83,210,1);
    //                 box-shadow:0px 0px 12px 1px rgba(40,110,252,0.31);
    //             }
    //             &:not(:last-child){
    //                 border-right:2px solid rgba(229,229,229,1);
    //             }
    //         }
    //     }
    //     &-box{
    //         margin-top: 24px;
    //         width: 100%;
    //         height: 580px;
    //         background:rgba(255,255,255,1);
    //         box-shadow:0px 0px 6px 0px rgba(51,51,51,0.11);
    //     }
    // }
}
</style>