<template>
  <div id="tax" class="smart-tax">
    <div class="smart-content">
      <h2>智慧税务</h2>
      <h4>智慧税务组成为税务处理、税务运营和税务分析</h4>
      <div class="map">
        <img v-lazy="share" alt="" />
      </div>
      <!-- <p>税务共享的构建方式</p> -->
    </div>
  </div>
</template>

<script>
import share from "../assets/img/bg/share.png";
export default {
  data() {
    return {
      share,
    };
  },
};
</script>

<style lang="scss" scoped>
.smart-tax {
  // width:100%;
  width: 80%;
  margin: 0 auto;
  // height:800px;
  background: rgba(255, 255, 255, 1);
  // text-align: center;
  .smart-content {
    width: 100%;
    margin: 0 auto;
    padding-top: 55px;
    h2 {
      //   margin-bottom: 47px;
      font-size: 30px;
      height: 65px;
      font-family: "Source Han Sans CN";
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      border-bottom: 1px solid #999;
      // line-height:21px;
    }
    h4 {
      width: 378px;
      height: 25px;
      font-size: 18px;
      //   font-family: PingFang SC-Regular, PingFang SC;
      //   font-weight: 400;
      margin-top: 0;
      color: #999999;
    }
    .map {
      width: 100%;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 10px 0px rgba(51, 51, 51, 0.11);
      margin: 0 auto;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      img {
        display: block;
        margin: 0 auto;
        width: 1180px;
      }
    }
    p {
      width: 324px;
      height: 50px;
      font-size: 36px;
      //   font-family: PingFang SC-Heavy, PingFang SC;
      font-weight: 800;
      //   color: #ffffff;
    }
  }
}
</style>
