<template>
  <div class="build-container">
    <div class="build-content">
      <div class="box clearfix">
        <div class="top">带来的变革</div>
        <div
          :class="['item', { active: item.isActive }]"
          v-for="(item, index) in list"
          :key="index"
          @mouseover="handleClick(item)"
          @mouseleave="item.isActive = false"
        >
          <span class="img">
            <img :src="!item.isActive ? item.url[0] : item.url[1]" alt="" />
          </span>
          <div class="info">
            <h3 class="title">{{ item.title }}</h3>
            <p
              :class="['desc', { active: item.isActive }]"
              v-html="item.desc"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import dpt_nor from "../assets/img/build/dpt_nor.png";
// import dpt_pre from "../assets/img/build/dpt_pre.png";
// import dsj_nor from "../assets/img/build/dsj_nor.png";
// import dsj_pre from "../assets/img/build/dsj_pre.png";
// import gzn_nor from "../assets/img/build/gzn_nor.png";
// import gzn_pre from "../assets/img/build/gzn_pre.png";
import zty_nor from "../assets/img/tp/1.png";
import zty_pre from "../assets/img/tp/2.png";
export default {
  data() {
    return {
      list: [
        {
          url: [zty_nor, zty_pre],
          isActive: false,
          title: "在线交易处理",
          desc: `及时的交易提醒和交易数据确认，订单的在线处理有效减少双方信息沟通<br/>
                采购订单和销售订单传递自动转换，实现采购订单的自动下载和销售订单的自动录入<br/>
                实现发货单与入库单的自动匹配`,
        },
        {
          url: [zty_nor, zty_pre],
          isActive: false,
          title: "及时计算对账",
          desc: `减少线下繁琐的线下对账，实现及时的计算确认<br/>实现明细的对账，减少对账差异<br/>
                发票自动识别处理、自动三单匹配、发票全生命周期的统一管理和追踪<br/>极大减少退票场景`,
        },
        {
          url: [zty_nor, zty_pre],
          isActive: false,
          title: "账款处理自动化",
          desc: `实现付款计划的自动生成，有效进行资金排期<br/>实施的付款状态，有利于双方把握资金
                节奏<br/>回款进度实施查询，减少沟通和对账成本<br/>可开展供应链融资业务`,
        },
        {
          url: [zty_nor, zty_pre],
          isActive: false,
          title: "数据分析应用",
          desc: `加强供应商交易行为分析<br/>通过资金效率分析，提升资金利用效率<br/>可采集销售
                单品周期数据，实现采购商品品类级别的数据分析`,
        },
      ],
      lastAcitve: {},
    };
  },
  methods: {
    handleClick(item) {
      this.lastAcitve.isActive = false;
      item.isActive = !item.isActive;
      this.lastAcitve = item;
    },
  },
  mounted() {
    this.lastAcitve = this.list[0];
  },
};
</script>

<style lang="scss" scoped>
.build-container {
  width: 100%;
  height: 580px;
  background: rgba(255, 255, 255, 1);
  .build-content {
    // width: 1200px;
    height: 100%;
    margin: 0 auto;
    h2 {
      text-align: center;
      letter-spacing: 2px;
      margin-bottom: 77px;
    }
    .box {
      .top {
        width: 100px;
        height: 28px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
      }
      padding-top: 102px;
      .item {
        float: left;
        width: calc(50% - 48px);
        // width: 590px;
        height: 180px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 6px 0px rgba(51, 51, 51, 0.11);
        border-radius: 4px;
        margin: 0 16px 16px 16px;
        display: flex;
        border: 1px solid #999999;
        cursor: pointer;
        &.active {
          //   background: rgba(44, 83, 210, 1);
          box-shadow: 0px 0px 12px 1px rgba(40, 110, 252, 0.31);
          border-radius: 4px;
          border: 1px solid #fff;
        }
        &:nth-child(2n) {
          margin-left: 0px;
        }
        &:nth-child(2n + 1),
        &:nth-child(2n + 3) {
          margin-left: 40px;
        }
        .img {
          width: 30px;
          height: 30px;
          //   width: 131px;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          margin-top: 27px;
          margin-left: 20px;
        }
        .info {
          //   flex: 1;
          //   display: flex;
          //   flex-direction: column;
          //   justify-content: center;
          margin-top: 19px;
          margin-left: 10px;
          .title {
            font-size: 16px;
            // margin-top: 27px;
          }
          .desc {
            color: #999999;
            font-size: 14px;
            padding: 0 4px;
            margin-top: 6px;
            line-height: 18px;
            &.active {
              color: #333333;
            }
          }
        }
      }
    }
  }
}
</style>