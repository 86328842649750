import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/home.vue'
import Login from '../pages/login.vue'
import Registered from '../pages/registered.vue'
import Echas from "../pages/echas.vue"
import serviceAgreement from '../pages/serviceAgreement.vue'
Vue.use(VueRouter)

const routes = [{
        path: "/",
        component: Home
    },
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/registered",
        name: "registered",
        component: Registered
    },
    {
        path: "/echas",
        name: "echas",
        component: Echas
    },
    {
        path: "/serviceAgreement",
        name: "serviceAgreement",
        component: serviceAgreement
    }
]

const router = new VueRouter({
    routes
})

export default router