import axios from './index.js'

const api = {
    postUser: '/user/getInfo',
    getsource: '/saasManagement/user/getSystemSource',
    login: '/saasManagement/login',
    findCompany: '/saasManagement/coreOrgManage/findGroupOrg',
    checkTaxNumber: '/saasManagement/coreOrgManage/checkTaxNumber',
}

// post请求函数
export const postUser = (data = {}) => {
    return axios({
        url: api.postUser,
        method: 'POST',
        data
    })
}
// export function postUser(data) {
//     return axios({
//         method: "post",
//         url: "/user/getInfo",
//         data
//     })
// }


// post请求函数
export const login = (data = {}) => {
    return axios({
        method: "post",
        url: api.login,
        data: data
    })
}
// export function login(data) {
//     return axios({
//         method: "post",
//         url: "/apSupport/login",
//         data
//     })
// }


// get 请求函数，使用params会自动序列化参数
export function getUser(params) {
    return axios({
        method: "post",
        url: "/user/getInfo",
        params
    })
}
// 获取手机验证码
export function getphone(params) {
    return axios({
        method: "GET",
        url: "/saasManagement/getMobileVerificationCode",
        params
    })
}
// 注册
export function toregister(data) {
    return axios({
        method: "POST",
        url: "/saasManagement/sysOrgManage/registerOrg",
        data
    })
}
// 获取邮箱验证码
export function getemail(params) {
    return axios({
        method: "GET",
        url: '/saasManagement/getEmailVerificationCode',
        params: {
            email: params
        }
    })
}

//添加系统服务
export function addsource(data) {
    return axios({
        method: 'POST',
        url: '/apSupport/user/registerSystem',
        data
    })
}
// 验证登录
export function setLogin(setlogin, setphone) {
    return axios({
        method: 'GET',
        url: '/saasManagement/verificationCodeLogin',
        params: {
            code: setphone,
            userName: setlogin
        }
    })
}
// 获取用户信息
export function getUserInfo() {
    return axios({
        method: 'POST',
        url: '/saasManagement/user/getHomeUserInfo',
    })
}
// 登录
export function tosoye(data) {
    return axios({
        method: 'POST',
        url: '/saasManagement/login',
        data
    })
}

// 获取系统列表
export const getsource = () => {
    return axios({
        url: api.getsource,
        method: 'GET',
    })
}
// export function getsource(params){
//     return axios({
//         method:'GET',
//         url:'/apSupport/user/getSystemSource',
//         params
//     })
// }

// 校验验证码
export function isSMS(data) {
    return axios({
        method: 'POST',
        url: '/saasManagement/checkVerificationCode',
        data
    })
}


// 查询集团公司
export function GetfindCompany(params) {
    return axios({
        method: 'GET',
        url: api.findCompany,
        params
    })
}

// 查询集团公司
export function getCheckTaxNumber(params) {
    return axios({
        method: 'GET',
        url: api.checkTaxNumber,
        params
    })
}