import Vue from 'vue'
import App from './App.vue'
import 'normalize.css'
import VueLazyload from 'vue-lazyload'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "@/router";
import * as echarts from 'echarts'
import vueBus from '@/utils/vueBus';
Vue.use(vueBus);

Vue.prototype.$echarts = echarts
// import ' lib-flexible'
// import router from './router';

Vue.use(VueLazyload);
Vue.use(ElementUI);

// or with options
Vue.use(VueLazyload, {
  // router,
  preLoad: 1.3,
  // error: 'dist/error.png',
  // loading: 'dist/loading.gif',
  attempt: 1,
  listenEvents: ['scroll']
})

Vue.config.productionTip = false

new Vue({
  el: "#app",
  router,
  render: h => h(App),
}).$mount('#app')