<template>
    <div>
        <top-bar></top-bar>
        <top-banner></top-banner>
        <build-container></build-container>
        <trade-container></trade-container>
        <time-axis></time-axis>
        <image-file></image-file>
        <!-- <smart-finance></smart-finance> -->
        <smart-tax></smart-tax>
        <our-feature></our-feature>
        <customer-case></customer-case>
        <about-us></about-us>
        <!-- <our-advantage></our-advantage> -->
        <bottom-footer></bottom-footer>
        <back-top></back-top>
    </div>
</template>

<script>
import TopBar from '../components/TopBar'
import TopBanner from '../components/TopBanner'
import BuildContainer from '../components/BuildContainer'
import TradeContainer from '../components/TradeContainer'
import TimeAxis from '../components/TimeAxis'
import ImageFile from '../components/ImageFile'
// import SmartFinance from '../components/SmartFinance'
import SmartTax from '../components/SmartTax'
import OurFeature from '../components/OurFeature'
import CustomerCase from '../components/CustomerCase'
import AboutUs from '../components/AboutUs'
// import OurAdvantage from '../components/OurAdvantage'
import BottomFooter from '../components/BottomFooter'
import BackTop from '../components/BackTop.vue'

export default {
    components: {
    'top-bar': TopBar,
    'top-banner': TopBanner,
    'build-container': BuildContainer,
    'trade-container': TradeContainer,
    'time-axis': TimeAxis,
    'image-file': ImageFile,
    // 'smart-finance': SmartFinance,
    'smart-tax': SmartTax,
    'our-feature': OurFeature,
    'customer-case': CustomerCase,
    'about-us': AboutUs,
    // 'our-advantage': OurAdvantage,
    'bottom-footer': BottomFooter,
    'back-top': BackTop
    },
    data () {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>

</style>