<template>
  <div>
    <header
      id="top-containter"
      :class="['top-containter', { 'header-scrolled': isScrolled }]"
    >
      <div class="top-bar clearfix">
        <div class="topbar-nav">
          <div
            class="nav-tabs__active-bar"
            :style="{ width: '56px', transform: `translateX(${curPosition})` }"
          ></div>
          <ul class="nav-wrap clearfix">
            <li
              v-for="(item, index) in navs"
              :key="index"
              @mouseover="handleMouseOver(item)"
              @mouseout="handleMouseOut(item)"
              class="nav clearfix"
            >
              <template v-if="item.name === 'logo'">
                <a
                  href="javascript:"
                  class="nav-name"
                  @click="handleClick(item)"
                >
                  <img v-lazy="item.imgUrl" alt="" />
                </a>
              </template>
              <template v-else-if="item.type === 'external'">
                <a
                  href="javascript:"
                  class="nav-name"
                  target="_blank"
                  @click="gogogo(item.ipUrl)"
                  >{{ item.name }}
                </a>
              </template>
              <template v-else>
                <a
                  href="javascript:"
                  class="nav-name"
                  @click="handleClick(item)"
                  >{{ item.name }}</a
                >
              </template>
            </li>
          </ul>
        </div>
        <!--          <div class="topbar-login" style="width: 80px;">-->
        <!--          </div>-->
        <div class="topbar-login">
          <div
            v-show="!userInfo.userName"
            class="operate login"
            @click="clicklogin"
          >
            登录
          </div>
          <!-- <a  v-show="!userInfo.userName" class="operate login" @click="clicklogin">登录</a> -->
          <!-- <router-link class="operate login" to="/login">登录</router-link> -->
          <!-- <a  v-show="!userInfo.userName" href="#" class="operate" @click="dialogRegisterFormVisible = true">注册</a> -->
          <div v-show="!userInfo.userName" class="operate" @click="onclid">
            注册
          </div>

          <!-- <a  v-show="!userInfo.userName" href="#" class="operate" @click="onclid">注册</a> -->
          <span v-show="userInfo.userName"> {{ userInfo.userName }}</span>

          <a
            v-show="userInfo.userName"
            href="#"
            class="operate"
            @click="loginOutFun()"
            >退出</a
          >
        </div>

        <div
          @mouseover="handleBoxOver"
          @mouseout="handleBoxOut"
          :class="['hover-box', { active: isShow }]"
        >
          <div
            class="item"
            @click="handleScroll(item)"
            v-for="(item, index) in childs"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </header>
    <!--    登录弹出框-->
    <el-dialog
      :visible.sync="dialogFormVisible"
      width="500px"
      :show-close="false"
      style="border-radius: 5px"
      :before-close="handleRegisterClose"
    >
      <div class="login_header">
        <img src="../assets/logo.png" alt="" style="" />
      </div>

      <el-form
        :model="loginForm"
        ref="loginForm"
        :rules="loginRules"
        style="width: 80%; margin: 50px auto 50px auto"
      >
        <el-form-item label="" style="margin-bottom: 30px" prop="userName">
          <el-input
            v-model="loginForm.userName"
            autocomplete="off"
            placeholder="请输入帐号"
          >
            <!--            <i slot="prepend" class="el-icon-user-solid"></i>-->
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input
            v-model="loginForm.password"
            type="password"
            autocomplete="off"
            placeholder="请输入密码"
          >
            <!--            <i slot="prepend" class="el-icon-lock"></i>-->
          </el-input>
        </el-form-item>
      </el-form>
      <div class="login_footer">
        <el-button
          type="primary"
          style="width: 40%; background: #143057; margin: 0px 0px 50px 0px"
          @click="login('loginForm')"
          >登 录</el-button
        >
        <el-button
          style="width: 40%; margin: 0px 0px 50px 0px"
          @click="handleRegisterClose"
          >取 消</el-button
        >
      </div>
    </el-dialog>

    <!--    注册弹出框-->
    <el-dialog
      :visible.sync="dialogRegisterFormVisible"
      width="500px"
      :show-close="false"
      :before-close="handleRegisterClose"
    >
      <div class="login_header">
        <img src="../assets/logo.png" alt="" style="" />
      </div>

      <el-form
        :model="registerForm"
        ref="registerForm"
        :rules="registerRules"
        style="width: 80%; margin: 50px auto 50px auto"
      >
        <el-form-item label="" style="margin-bottom: 30px" prop="orgName">
          <el-input
            v-model="registerForm.orgName"
            autocomplete="off"
            placeholder="请输入公司名称"
          >
            <!--            <i slot="prepend" class="el-icon-user-solid"></i>-->
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="companyTaxNumber">
          <el-input
            v-model="registerForm.companyTaxNumber"
            autocomplete="off"
            placeholder="请输入公司税号"
          >
            <!--            <i slot="prepend" class="el-icon-lock"></i>-->
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="orgEmail">
          <el-input
            v-model="registerForm.orgEmail"
            autocomplete="off"
            placeholder="请输入邮箱"
          >
            <!--            <i slot="prepend" class="el-icon-lock"></i>-->
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="orgPhone">
          <el-input
            v-model="registerForm.orgPhone"
            autocomplete="off"
            placeholder="请输入联系电话"
          >
            <!--            <i slot="prepend" class="el-icon-lock"></i>-->
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="orgAddress">
          <el-input
            v-model="registerForm.orgAddress"
            autocomplete="off"
            placeholder="请输入地址"
          >
            <!--            <i slot="prepend" class="el-icon-lock"></i>-->
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="userName">
          <el-input
            v-model="registerForm.userName"
            autocomplete="off"
            placeholder="请输入账号"
          >
            <!--            <i slot="prepend" class="el-icon-lock"></i>-->
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input
            v-model="registerForm.password"
            type="password"
            autocomplete="off"
            placeholder="请输入密码"
          >
            <!--            <i slot="prepend" class="el-icon-lock"></i>-->
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="systemSource">
          <el-checkbox-group v-model="registerForm.systemSource">
            <el-checkbox
              v-for="(item, index) in systemSourceList"
              :label="item.sysSourceCode"
              :key="index"
              >{{ item.sysSourceName }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div class="login_footer">
        <el-button
          type="primary"
          style="width: 40%; margin: 0px 0px 50px 0px; background: #143057"
          @click="register('registerForm')"
          >注 册</el-button
        >
        <el-button
          style="width: 40%; margin: 0px 0px 50px 0px"
          @click="handleRegisterClose"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import logo from "../assets/logo.png";
import { getsource, getUserInfo } from "../api/user.js";
import { registerUser, postLogin, postLogout } from "../assets/js/api.js";

export default {
  data() {
    var validateName = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入帐号"));
      } else {
        callback();
      }
    };

    var validatePass = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      navs: [
        { type: "logo", name: "logo", imgUrl: logo },
        { type: "home", name: "首页" },
        { type: "none-scroll", name: "智慧财务" },
        { type: "tax", name: "智慧税务" },
        { type: "customer", name: "客户案例" },
        { type: "about", name: "关于慧财" },
      ],
      isScrolled: false,
      isShow: false,
      timer: null,
      curPosition: "180px",
      childs: [
        { type: "trade", name: "企业间在线交易结算" },
        { type: "image-file", name: "电子档案影像" },
        { type: "finance", name: "智慧财务-RPA" },
      ],
      loginForm: {
        userName: "",
        password: "",
      },
      loginRules: {
        userName: [{ validator: validateName, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
      },
      dialogFormVisible: false,
      formLabelWidth: "50px",

      registerForm: {
        orgName: "",
        companyTaxNumber: "",
        orgEmail: "",
        orgPhone: "",
        orgAddress: "",
        userName: "",
        password: "",
        systemSource: [],
      },
      registerRules: {
        orgName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        companyTaxNumber: [
          { required: true, message: "请输入公司税号", trigger: "blur" },
        ],
        orgEmail: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        orgPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        orgAddress: [
          { required: true, message: "请输入地址", trigger: "blur" },
        ],
        userName: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        systemSource: [
          { required: true, message: "请输入选择系统", trigger: "blur" },
        ],
      },
      dialogRegisterFormVisible: false,

      userInfo: "",
      systemSourceList: [
        {
          ipUrl: "http://192.168.0.109:3100",
          sysSourceCode: "1",
          sysSourceEname: "SCM",
          sysSourceName: "档案系统",
        },
        {
          ipUrl: "http://192.168.0.109:3100",
          sysSourceCode: "2",
          sysSourceEname: "ARC",
          sysSourceName: "影像系统",
        },
        {
          ipUrl: "http://192.168.0.109:3100",
          sysSourceCode: "3",
          sysSourceEname: "CTP",
          sysSourceName: "查验识别云平台",
        },
      ],
      urlList: [],
    };
  },
  async created() {
    const result = await getsource();
    // console.log(result);
    this.copanyLists = result.data.result;
    this.services = result.data.result;
    // console.log(result.data.result);
    // console.log(this.copanyLists);
    this.urlList = {
      [result.data.result[0].sysSourceEname]: result.data.result[0].ipUrl,
      [result.data.result[1].sysSourceEname]: result.data.result[1].ipUrl,
      [result.data.result[2].sysSourceEname]: result.data.result[2].ipUrl,
      [result.data.result[3].sysSourceEname]: result.data.result[3].ipUrl,
    };
  },
  methods: {
    // 跳转到注册
    onclid() {
      // this.$emit('touh')
      this.$router.push("/registered?isao=false");
    },
    // 点击切换login
    clicklogin() {
      // this.$emit('onShow')
      this.$router.push("/login?isao=true");
    },
    handleScroll(item) {
      if (item.type !== "none-scroll") {
        if (item.type === "home" || item.type === "logo") {
          this.scrollToAnchor("top-banner");
        } else {
          this.scrollToAnchor(item.type);
        }
      }
    },
    handleClick(item) {
      if (item.type === "home" || item.type === "logo") {
        this.curPosition = "180px";
      } else if (item.type === "none-scroll") {
        this.curPosition = "276px";
      } else if (item.type === "tax") {
        this.curPosition = "388px";
      } else if (item.type === "customer") {
        this.curPosition = "500px";
      } else if (item.type === "about") {
        this.curPosition = "610px";
      }
      this.handleScroll(item);
    },
    scrollToAnchor(anchorName) {
      if (anchorName) {
        // 找到锚点
        let anchorElement = document.getElementById(anchorName);
        // 如果对应id的锚点存在，就跳转到锚点
        if (anchorElement) {
          anchorElement.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }
    },
    scrollHandler() {
      if (document.documentElement.scrollTop > 0) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
      if (document.documentElement.scrollTop < 1397) {
        this.curPosition = "180px";
      } else if (
        document.documentElement.scrollTop >= 1397 &&
        document.documentElement.scrollTop < 4517
      ) {
        this.curPosition = "276px";
      } else if (
        document.documentElement.scrollTop >= 4517 &&
        document.documentElement.scrollTop < 5817
      ) {
        this.curPosition = "388px";
      } else if (
        document.documentElement.scrollTop >= 5817 &&
        document.documentElement.scrollTop < 6517
      ) {
        this.curPosition = "500px";
      } else if (document.documentElement.scrollTop >= 6517) {
        this.curPosition = "610px";
      }
    },
    handleMouseOver(item) {
      if (item.type === "home") {
        this.curPosition = "180px";
      } else if (item.type === "none-scroll") {
        // if(this.timer) clearTimeout(this.timer);
        // this.timer = setTimeout(() => {
        this.isShow = true;
        // }, 500);
        this.curPosition = "276px";
      } else if (item.type === "tax") {
        this.curPosition = "388px";
      } else if (item.type === "customer") {
        this.curPosition = "500px";
      } else if (item.type === "about") {
        this.curPosition = "610px";
      }
    },
    handleMouseOut(item) {
      if (item.type !== "none-scroll") {
        return;
      }
      // if(this.timer) clearTimeout(this.timer);
      // this.timer = setTimeout(() => {
      this.isShow = false;
      // }, 500);
      // this.isShow = false
    },
    handleBoxOver() {
      this.curPosition = "276px";
      this.isShow = true;
    },
    handleBoxOut() {
      this.isShow = false;
    },
    login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          postLogin(this.loginForm).then((res) => {
            this.$message({
              message: "恭喜你，登录成功!",
              type: "success",
            });
            // 存入缓存
            localStorage.setItem("homeToken", res.result.access_token);
            this.handleRegisterClose();
            this.getuser();
          });
        } else {
          return false;
        }
      });
    },
    getuser() {
      let token = localStorage.getItem("homeToken");
      console.log(token);
      getUserInfo()
        .then((res) => {
          console.log(res);
          console.log(res.data.code === 0);

          // }
          // this.userInfo = res.data.result;
          // for (const re of res.result.systemSource) {
          //   for (const reElement of this.systemSourceList) {
          //     if (re == reElement.sysSourceCode)
          //       this.navs.push({
          //         type: "external",
          //         name: reElement.sysSourceName,
          //         ipUrl: reElement.ipUrl,
          //       });
          //     }
          //   }
          // }
        })
        .catch(() => {
          // this.userInfo = '';
          localStorage.clear("homeToken");
        });
    },
    register() {
      this.$refs.registerForm.validate(async (valid) => {
        if (valid) {
          registerUser(this.registerForm).then(() => {
            this.$message({
              message: "恭喜你，注册成功!",
              type: "success",
            });
            this.handleRegisterClose();
          });
        } else {
          return false;
        }
      });
    },
    gogogo(url) {
      window.console.log(url);
      window.location.href =
        url + "?token=" + localStorage.getItem("homeToken");
    },
    handleRegisterClose() {
      this.dialogFormVisible = false;
      this.dialogRegisterFormVisible = false;
      for (let re in this.registerForm) {
        if (re == "systemSource") {
          this.registerForm[re] = [];
        } else {
          this.registerForm[re] = "";
        }
      }
      for (let re in this.loginForm) {
        this.loginForm[re] = "";
      }
    },
    loginOutFun() {
      localStorage.removeItem("homeToken");
      // 清除用户信息
      this.userInfo = "";
      // 重新赋值菜单
      this.navs = [
        { type: "logo", name: "logo", imgUrl: logo },
        { type: "home", name: "首页" },
        { type: "none-scroll", name: "智慧财务" },
        { type: "tax", name: "智慧税务" },
        { type: "customer", name: "客户案例" },
        { type: "about", name: "关于慧财" },
      ];
      this.$message({
        message: "恭喜你，退出成功!",
        type: "success",
      });
      postLogout({}, { LOGINTOKENKEY: localStorage.getItem("homeToken") })
        .then(() => {
          // 清除token
          // localStorage.setItem('homeToken','');
          localStorage.removeItem("homeToken");
          // 清除用户信息
          this.userInfo = "";
          // 重新赋值菜单
          this.navs = [
            { type: "logo", name: "logo", imgUrl: logo },
            { type: "home", name: "首页" },
            { type: "none-scroll", name: "智慧财务" },
            { type: "tax", name: "智慧税务" },
            { type: "customer", name: "客户案例" },
            { type: "about", name: "关于慧财" },
          ];
          this.$message({
            message: "恭喜你，退出成功!",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandler);
    // ‘ 暂时 ’ 前端写死
    getsource().then((res) => {
      this.systemSourceList = res.result;
    });
    // 查看缓存是否有 homeToken
    let homeToken = localStorage.getItem("homeToken");
    console.log(homeToken);
    if (homeToken) {
      this.getuser();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandler);
  },
};
</script>

<style lang="scss">
.top-containter {
  // height: 80px;
  flex: 999;
  background-color: #007aff;
  color: #fff;
  position: fixed;
  width: 100%;
  z-index: 30;
  -webkit-transition: all 0.4s ease-in-out;
  border-bottom: 1px solid #fff3;
  &.header-scrolled {
    padding: 0;
    // background: #fff;
    color: #fff;
    opacity: 0.8;
    // box-shadow: 0 0 4px rgba(0,0,0,.5);
  }
  .top-bar {
    position: relative;
    width: 80%;
    margin: 0 auto;
    font-size: 12px;
    z-index: 11;
    .topbar-nav {
      float: left;
      overflow: hidden;
      .nav-tabs__active-bar {
        position: absolute;
        bottom: 12px;
        left: 0;
        height: 2px;
        background: rgba(65, 123, 255, 1);
        z-index: -1;
        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        list-style: none;
      }
      .nav-wrap {
        .nav {
          float: left;
          font-size: 16px;
          font-family: "SourceHanSansCN-Medium";
          height: 80px;
          line-height: 80px;
          padding: 0 24px;
          padding-left: 0;
          .nav-name {
            img {
              vertical-align: middle;
            }
          }
          // &:nth-child()
        }
      }
    }
    .topbar-login {
      float: right;
      font-size: 16px;
      height: 80px;
      line-height: 80px;
      width: 160px;
      display: flex;
      align-items: center;
      span {
        width: 90px;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: right;
      }
      .operate {
        width: 80px;
        height: 30px;
        line-height: 30px;
        // float: right;
        display: inline-block;
        text-align: center;
        margin-left: 20px;
        border: 1px solid #fff;
        // &:nth-child(2){
        //   margin-right: 0;
        // }
        &.login {
          // background: rgba(44, 83, 210, 1);
          border-radius: 4px;
          color: #007aff;
          background: #fff;
        }
      }
    }
    .hover-box {
      position: absolute;
      left: 231px;
      top: 81px;
      width: 160px;
      box-sizing: border-box;
      height: 100px;
      background: rgba(44, 83, 210, 1);
      padding: 10px 0px;
      display: none;
      -webkit-transition: all 0.4s ease-in-out;
      &.active {
        display: block;
      }
      .item {
        text-align: center;
        font-size: 14px;
        line-height: 27px;
        cursor: pointer;
        height: 27px;
        font-family: "PingFang SC";
        // font-weight:bold;
        color: rgba(255, 255, 255, 1);
        line-height: 30px;
      }
    }
  }
}
// .el-dialog {
//   //border-radius: 5px !important;
// }

.el-dialog__header {
  padding: 0 !important;
}
.el-dialog__body {
  padding: 0 !important;
  //border-radius:10px;
}
.login_header {
  background: #143057;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px 2px 0px 0px;
}
.login_footer {
  text-align: center;
  padding: 0px 10%;
  display: flex;
  justify-content: space-between;
}
</style>
