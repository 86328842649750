<template>
  <div id="customer" class="customer-case">
    <div class="custom-content">
      <h2 class="top">多家时代先行企业的选择</h2>
      <div class="list">
        <!-- <span class="item" v-for="(item,index) in list" :key="index">
                    <img v-lazy="item" alt="">
                </span> -->
        <img src="../assets/img/partner/cy.png" />
        <img src="../assets/img/partner/hayao.png" />
        <img src="../assets/img/partner/kunyao.png" />
        <img src="../assets/img/partner/lg.png" />
        <img src="../assets/img/partner/zz.png" />
        <img src="../assets/img/partner/sy.png" />
        <img src="../assets/img/partner/xa.png" />
        <img src="../assets/img/partner/zq.png" />
        <img src="../assets/img/partner/st.png" />
      </div>
    </div>
  </div>
</template>

<script>
// import cy from '../assets/img/partner/cy.png'
// import hy from '../assets/img/partner/hy.png'
// import ky from '../assets/img/partner/ky.png'
// import lg from '../assets/img/partner/lg.png'
// import sq from '../assets/img/partner/sq.png'
// import sy from '../assets/img/partner/sy.png'
// import xa from '../assets/img/partner/xa.png'
// import zq from '../assets/img/partner/zq.png'
// import st from '../assets/img/partner/st.png'
export default {
  data() {
    return {
      // list:Object.freeze([cy,hy,ky,lg,sq,sy,xa,zq,st])
    };
  },
};
</script>

<style lang="scss" scoped>
.customer-case {
  width: 100%;
  height: 680px;
  background: rgba(242, 242, 242, 1);
  .custom-content {
    padding-top: 80px;
    // width: 1226px;
    width: 80%;
    margin: 0 auto;
    h2 {
      margin: -30px 0 77px 0;
      // text-align: center;
      // width: 80%;
      height: 65px;
      border-bottom: 1px solid #9999;
      font-size: 30px;
      font-family: "Source Han Sans CN";
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      // line-height:21px;
    }
    .list {
      width: 100%;
      height: 500px;
      // display: flex;
      // justify-content:center ;
      margin: 0 auto;
      img {
        width: 27%;
        height: 100px;
        margin: 0 9% 40px 0;
        &:nth-child(3n + 3) {
          margin-right: 0px;
        }
      }
      // .item{
      //     float: left;
      //     // display: flex;
      //     // justify-content: center;
      //     margin: 0 90px 40px 0px;
      //     &:nth-child(3n+3){
      //         margin-right: 0px;
      //     }
      // }
    }
  }
}
</style>