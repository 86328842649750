import axios from 'axios'
import { baseUrl } from './api.config'
import { Message  } from 'element-ui';

export default function(url,params,methodType='GET',token){
    return new Promise((resolve, reject) => {
        axios.request({
            url:url,
            method: methodType,
            baseURL:baseUrl,
            data:params,
            timeout: 1000 * 60,
            headers:token
        }).then(res=>{
            window.console.log('url')
            window.console.log(url)
            // if (!['/apSupport/user/getHomeUserInfo'].includes(url)){
                if(res.data.code === 0){
                    resolve(res.data)
                }else if (['/apSupport/user/getHomeUserInfo'].includes(url) && res.data.code !== 0) {
                    reject(res)
                }else  {
                    Message.error(res.data.message)
                    reject(res)
                }
            // }
        }).catch(err=>{
            if(err.message.includes('timeout')){
                // 请求超时
                Message.error('接口请求超时，请稍后再试')
            }
            // console.log(`err.data.message`)
            // console.log(err)
            // message({
            //     message: err.data.message,
            //     type: 'error'
            // });
            reject(err)
        }).finally(()=>{
            // 请求结束
        })
    })
}