<template>
  <div id="top-banner" class="top-banner">
    <div class="box">
      <div class="node">
        <img src="../assets/img/login/16842.png" />
      </div>
      <div class="banner-info">
        <div class="title">面向未来的智慧财税服务提供商</div>
        <div class="desc">
          <p class="info">税务风险预警</p>
          <p class="info">税务处理</p>
          <p class="info">集团财务影像档案解决方案</p>
          <p class="info">企业间在线交易结算解决方案</p>
        </div>
        <div class="btm">立即订购</div>
      </div>
      <div class="button">
        <div class="butbf">
          <div class="buttop">
            <span>50</span>
            <span class="butfh">+</span>
          </div>
          <div class="butjx">专业服务团队</div>
        </div>
        <div class="butbf">
          <div class="buttop">
            <span>90</span>
            <span class="butfh">%</span>
          </div>
          <div class="butjx">员工为技术人才</div>
        </div>
        <div class="butbf">
          <div class="buttop">
            <span>30</span>
            <span class="butfh">+</span>
          </div>
          <div class="butjx">大型集团企业优选</div>
        </div>
        <div class="butbf">
          <div class="buttop">
            <span>5</span>
            <span class="butfh">+</span>
          </div>
          <div class="butjx">稳定和长效</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.top-banner {
  position: relative;
  .node {
    position: absolute;
    top: 50px;
    left: 0;
    // width: 100%;
    // height: 100%;
    img {
      width: 100%;
      height: 100%;
      // margin: 0 auto;
      position: relative;
      left: 78px;
    }
    // background-image: url('../assets/img/login/16842.png');
  }
}
.top-banner {
  position: relative;
  background: url("../assets/img/login/15913.png") center no-repeat;
  background-size: 100% 100%;
  height: 856px;
  width: 100%;
  color: #fff;
  .banner-info {
    position: relative;
    top: 272px;
    width: 80%;
    margin: 0 auto;
    .title {
      // width:1164px;
      height: 76px;
      line-height: 72px;
      font-size: 50px;
      font-family: "Source Han Sans CN";
      font-weight: bold;
      color: #00edff;
      // text-align: center;
      letter-spacing: 6px;
      margin-bottom: 69px;
      // margin-left: 310px;
    }
    .desc {
      // width: 360px;
      // text-align: center;
      margin-left: -312px;
      .info {
        margin-left: 310px;
        width: 353px;
        height: 26px;
        line-height: 26px;
        font-size: 21px;
        font-family: "Source Han Sans CN";
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        // &:nth-child(2){
        //     margin: 0 31px 0 0px;
        // }
        &:nth-child(3) {
          margin-right: 25px;
        }
      }
    }
    .btm {
      width: 130px;
      height: 43px;
      background: #f29423;
      //   opacity: 1;
      //   align-items: center;
      text-align: center;
      line-height: 43px;
      // margin-left: 310px;
      font-size: 21px;
    }
  }
  .button {
    display: flex;
    width: 80%;
    //   margin: 0 auto;
    margin-left: 10%;
    height: 240px;
    //   border: 1px solid #f29423;
    position: absolute;
    bottom: -120px;
    background-color: #fff;
    box-shadow: 0px 0px 6px 0px rgba(51, 51, 51, 0.11);
    //   margin-bottom: 100px;
    .butbf {
      width: 25%;
      height: 240px;
      // line-height: 240px;
      // border: 1px solid #00edff;
      text-align: center;
      // background: #fff;
      margin-top: 43px;
      .buttop {
        font-size: 80px;
        color: #007aff;
        .butfh {
          font-size: 48px;
        }
      }
      .butjx {
        margin-top: 10px;
        color: #007aff;
        font-size: 24px;
      }
    }
  }
}
</style>
