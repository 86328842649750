<template>
  <div v-lazy:background-image="imgUrl" class="our-feature">
    <div class="top">税务共享的构建方式</div>
    <div class="our-content clearfix">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="img">
          <img v-lazy="item.url" alt="" />
        </div>
        <h3>{{ item.title }}</h3>
        <p v-html="item.desc"></p>
      </div>
    </div>
  </div>
</template>

<script>
import fxyj from "../assets/img/smart/fxyj.png";
import nssb from "../assets/img/smart/nssb.png";
import swcl from "../assets/img/smart/swcl.png";
import zdkj from "../assets/img/smart/zdkj.png";
export default {
  data() {
    return {
      list: Object.freeze([
        {
          url: swcl,
          title: "发票税务处理",
          desc: "发票下载实现采购发票即开即得</br>发票查验、认证通过批量完成",
        },
        { url: nssb, title: "自动纳税申报", desc: "全税种纳税申报一键完成" },
        {
          url: zdkj,
          title: "发票自动开具",
          desc: "开票数据通过拆分、合并等</br>规则自动生成并开具",
        },
        {
          url: fxyj,
          title: "税务风险预警",
          desc: "根据行业实现税务风险实时</br>预警",
        },
      ]),
      imgUrl: require("../assets/img/bg/interchip.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
.our-feature {
  width: 100%;
  height: 380px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  color: #fff;
  margin-top: 80px;
  .top {
    width: 80%;
    height: 50px;
    font-size: 36px;
    //   font-family: PingFang SC-Heavy, PingFang SC;
    font-weight: 800;
    line-height: 150px;
    margin: 0 auto;
    color: #ffffff;
    // margin-top: 50px;
  }
  .our-content {
    width: 80%;
    height: 380px;
    margin: 0 auto;
    padding-top: 85px;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .item {
      //   margin-top: 20px;
      //   float: left;
      width: 260px;
      left: 0;
      text-align: center;
      &:nth-child(2) {
        margin: 0px 94px 0px 113px;
      }
      &:nth-child(3) {
        margin-right: 61px;
      }
      h3 {
        // font-size: 24px;
        // font-family: "Source Han Sans CN";
        // font-weight: bold;
        // color: rgba(255, 255, 255, 1);
        // line-height: 21px;

        width: 100%;
        text-align: center;
        height: 33px;
        font-size: 24px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 0px;
      }
      p {
        // font-size: 16px;
        // font-family: "Source Han Sans CN";
        // font-weight: 500;
        // color: rgba(255, 255, 255, 1);
        // line-height: 24px;
        // opacity: 0.7;
        width: 100%;
        font-size: 18px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        text-align: left;
        padding-left: 30px;
      }
    }
  }
}
</style>