<template>
  <div class="echas">
    <div class="heda">
      <img src="../assets/img/echas/logo.png" />
    </div>
    <div class="hon">
      <div class="boxSty" :class="{ TaxService: isSo1 }" @click="toxt1">
        <div class="textTitle"><p>企业税务服务</p></div>
        <div class="TextImg">
          <div class="imgText">
            <p>发票查验、认证</p>
            <p>企业自动纳税申报</p>
            <p>业务税务风险控制</p>
          </div>
          <div class="imgSty">
            <img src="../assets/img/echas/333.png" />
          </div>
        </div>
      </div>
      <div class="boxSty" :class="{ ImagingServices: isSo2 }" @click="toxt2">
        <div class="textTitle"><p>财务影像服务</p></div>
        <div class="TextImg">
          <div class="imgText">
            <p>财务影像采集</p>
            <p>财务智能审核</p>
            <p>财务审核安全、合规控制</p>
            <p>票证核对</p>
          </div>
          <div class="imgSty">
            <img src="../assets/img/echas/555.png" />
          </div>
        </div>
      </div>
      <div class="boxSty" :class="{ ArchivesService: isSo3 }" @click="toxt3">
        <div class="textTitle"><p>财务档案服务</p></div>
        <div class="TextImg">
          <div class="imgText">
            <p>财务档案采集</p>
            <p>财务档案归档</p>
            <p>财务档案检索</p>
            <p>财务档案借阅</p>
          </div>
          <div class="imgSty">
            <img src="../assets/img/echas/133.png" />
          </div>
        </div>
      </div>
      <div class="boxSty" :class="{ ArchivesService: isSo4 }" @click="toxt4">
        <div class="textTitle"><p>供应商对账服务</p></div>
        <div class="TextImg">
          <div class="imgText">
            <p>供应商交易确认（业务对账）</p>
            <p>供应商智能开票</p>
            <p>供应商自动报账</p>
            <p>供应商财务对账</p>
          </div>
          <div class="imgSty">
            <img src="../assets/img/echas/177.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="bute">
      <div class="left">
        <div class="cess">
          <div class="ecsxu">
            <div id="myChart" :style="{ width: '100%', height: '100%' }"></div>
          </div>
          <div class="ecsxu">
            <div id="myChart2" :style="{ width: '100%', height: '100%' }"></div>
          </div>
          <div class="ecsxu">
            <div id="myChart3" :style="{ width: '100%', height: '100%' }"></div>
          </div>
          <div class="ecsxu">
            <div id="myChart4" :style="{ width: '100%', height: '100%' }"></div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="tozi">通知</div>
        <div class="xinxi">
          <span class="xuhao">1</span>
          <span class="orde">通知消息</span>
          <span class="mix">2分钟前</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "echas",
  data() {
    return {
      isSo1: false,
      isSo2: false,
      isSo3: false,
      isSo4: false,
      urLists: "",
      oneYear: [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ],
    };
  },
  created() {
    this.getSource();
    this.urLists = this.$route.query.ipUrl;
    console.log(this.urLists);
  },
  mounted() {
    this.drawLine();
    this.drawLine2();
    this.drawLine3();
    this.drawLine4();
  },
  methods: {
    // 跳转到新页面 SCM: 'http://39.104.87.53:6880'
    toxt1() {
      if (this.isSo1 == false) return;
      let tonken = localStorage.getItem("homeToken");
      window.location.href = `${this.urLists["SCM"]}?token=${tonken}`;
    },
    toxt2() {
      if (this.isSo2 == false) return;
      let tonken = localStorage.getItem("homeToken");
      window.location.href = `${this.urLists["ARC"]}?token=${tonken}`;
    },
    toxt3() {
      if (this.isSo3 == false) return;
      let tonken = localStorage.getItem("homeToken");
      window.location.href = `${this.urLists["CTP"]}?token=${tonken}`;
    },
    toxt4() {
      if (this.isSo4 == false) return;
      let tonken = localStorage.getItem("homeToken");
      window.location.href = `${this.urLists["SRS"]}?token=${tonken}`;
    },
    // 获取选择系统设置高亮
    getSource() {
      console.log(this.$route.query.systemSource);
      console.log(this.$route.query.ipUrl);
      console.log(this.$route.query.ipUrl["SCM"]);
      // console.log(this.$route.query.ipUrl);
      this.isSo1 = this.$route.query.systemSource.includes("SCM");
      this.isSo2 = this.$route.query.systemSource.includes("ARC");
      this.isSo3 = this.$route.query.systemSource.includes("CTP");
      this.isSo4 = this.$route.query.systemSource.includes("SRS");
    },
    drawLine4() {
      // 基于准备好的dom，初始化echarts实例
      let myChart4 = this.$echarts.init(document.getElementById("myChart4"));
      // 绘制图表
      window.addEventListener("resize", function () {
        myChart4.resize();
      });
      myChart4.setOption({
        title: { text: "" },
        tooltip: {},
        xAxis: {
          data: this.oneYear,
          axisTick: {
            show: false,
          },
          boundaryGap: false,
          // 修改图标x轴底线颜色
          axisLine: {
            lineStyle: {
              color: "#8E95AA",
              opacity: 0.2,
            },
          },
          // 修改x轴字体配置
          axisLabel: {
            textStyle: {
              show: true,
              fontFamily: "Source Han Sans CN-Regular, Source Han Sans CN",
              color: "#8E95AA",
              fontSize: "12",
              fontWeight: 400,
            },
          },
        },
        yAxis: {
          data: ["10", "20", "30"],
          type: "value",
          boundaryGap: false,
          axisTick: {
            show: false, //y轴线消失
          },
          // 修改图表y轴颜色（splitLine）
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#8E95AA"],
              width: 1,
              type: "solid",
              opacity: 0.2,
            },
          },
          // 修改图标y轴底线颜色
          axisLine: {
            lineStyle: {
              color: "#8E95AA",
              opacity: 0.2,
            },
          },
          // 修改y轴字体配置
          axisLabel: {
            textStyle: {
              show: true,
              fontFamily: "Source Han Sans CN-Regular, Source Han Sans CN",
              color: "#8E95AA",
              fontSize: "12",
              fontWeight: 400,
            },
          },
        },
        series: [
          {
            name: "销量",
            type: "line",
            data: [2, 5, 8, 7, 8, 7, 10, 11, 12, 15, 20, 18],
            symbolSize: 8,
            symbol: "circle",
            itemStyle: {
              normal: {
                color: "#57D8A0",
              },
            },
            smooth: true,
          },
        ],
      });
    },
    drawLine3() {
      // 基于准备好的dom，初始化echarts实例
      let myChart3 = this.$echarts.init(document.getElementById("myChart3"));
      // 绘制图表
      window.addEventListener("resize", function () {
        myChart3.resize();
      });
      myChart3.setOption({
        title: { text: "" },
        tooltip: {},
        xAxis: {
          data: this.oneYear, //1 x轴参数
          axisTick: {
            show: false,
          },
          boundaryGap: false,
          // 修改图标x轴底线颜色
          axisLine: {
            lineStyle: {
              color: "#8E95AA",
              opacity: 0.2,
            },
          },
          // 修改x轴字体配置
          axisLabel: {
            textStyle: {
              show: true,
              fontFamily: "Source Han Sans CN-Regular, Source Han Sans CN",
              color: "#8E95AA",
              fontSize: "12",
              fontWeight: 400,
            },
          },
        },
        yAxis: {
          data: ["1000", "2000", "3000"], // 2  y轴参数

          type: "value",
          boundaryGap: false,
          axisTick: {
            show: false, //y轴线消失
          },
          // 修改图表y轴颜色（splitLine）
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#8E95AA"],
              width: 1,
              type: "solid",
              opacity: 0.2,
            },
          },
          // 修改图标y轴底线颜色
          axisLine: {
            lineStyle: {
              color: "#8E95AA",
              opacity: 0.2,
            },
          },
          // 修改y轴字体配置
          axisLabel: {
            textStyle: {
              show: true,
              fontFamily: "Source Han Sans CN-Regular, Source Han Sans CN",
              color: "#8E95AA",
              fontSize: "12",
              fontWeight: 400,
            },
          },
        },
        series: [
          {
            name: "销量",
            type: "line",
            // 3 渲染图标参数 多条则多个参数
            data: [
              3000, 2500, 4200, 3700, 4500, 3500, 3200, 5000, 2000, 2900, 4500,
              3500,
            ],
            symbolSize: 8,
            symbol: "circle",
            // lineStyke:{
            // normal:{
            //   color:'#3F8EFB '
            // }
            // },
            itemStyle: {
              normal: {
                color: "#65AFFF",
                // borderColor: "#3F8EFB ",
              },
              emphasis: {
                color: "#3F8EFB ",
              },
            },
            smooth: true,
          },
          {
            name: "销量",
            type: "line",
            data: [
              1500, 2500, 3000, 3050, 2500, 1400, 3200, 4000, 2030, 2900, 4500,
              3500,
            ],
            symbolSize: 8,
            symbol: "circle",
            itemStyle: {
              normal: {
                color: "#F3BB5E",
              },
            },
            smooth: true,
          },
        ],
      });
    },
    drawLine2() {
      // 基于准备好的dom，初始化echarts实例
      let myChart2 = this.$echarts.init(document.getElementById("myChart2"));
      // 绘制图表
      window.addEventListener("resize", function () {
        myChart2.resize();
      });
      myChart2.setOption({
        title: { text: "" },
        tooltip: {},
        xAxis: {
          data: this.oneYear,
          // 修改图标x轴底线颜色
          axisLine: {
            lineStyle: {
              color: "#8E95AA",
              opacity: 0.2,
            },
          },
          // 修改x轴字体配置
          axisLabel: {
            textStyle: {
              show: true,
              fontFamily: "Source Han Sans CN-Regular, Source Han Sans CN",
              color: "#8E95AA",
              fontSize: "12",
              fontWeight: 400,
            },
          },
        },
        yAxis: {
          data: ["1000", "2000", "3000"],
          type: "value",
          // 修改图表y轴颜色（splitLine）
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#8E95AA"],
              width: 1,
              type: "solid",
              opacity: 0.2,
            },
          },
          // 修改图标y轴底线颜色
          axisLine: {
            lineStyle: {
              color: "#8E95AA",
              opacity: 0.2,
            },
          },
          // 修改y轴字体配置
          axisLabel: {
            textStyle: {
              show: true,
              fontFamily: "Source Han Sans CN-Regular, Source Han Sans CN",
              color: "#8E95AA",
              fontSize: "12",
              fontWeight: 400,
            },
          },
        },
        series: [
          {
            name: "销量",
            type: "line",
            data: [
              1000, 1200, 1500, 1700, 1800, 2000, 2100, 2200, 2500, 2500, 2600,
              2800,
            ],
            symbolSize: 8,
            symbol: "circle",
            itemStyle: {
              normal: {
                color: "#65AFFF",
              },
              // emphasis: {
              //   color: "#3F8EFB ",
              // },
              // },
              // 一列图标颜色
              // areaStyle: {
              //   color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //     {
              //       offset: 0,
              //       color: "#00CDFF",
              //     },
              //     {
              //       offset: 1,
              //       color: "#6EE0FB",
              //     },
              //   ]),
            },
            smooth: true,
          },
        ],
      });
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart1 = this.$echarts.init(document.getElementById("myChart"));
      // 绘制图表
      window.addEventListener("resize", function () {
        myChart1.resize();
      });
      myChart1.setOption({
        title: { text: "" },
        tooltip: {},
        xAxis: {
          data: this.oneYear,
          axisTick: {
            show: false,
          },
          // 修改图标x轴底线颜色
          axisLine: {
            lineStyle: {
              color: "#8E95AA",
              opacity: 0.2,
            },
          },
          // 修改x轴字体配置
          axisLabel: {
            textStyle: {
              show: true,
              fontFamily: "Source Han Sans CN-Regular, Source Han Sans CN",
              color: "#8E95AA",
              fontSize: "12",
              fontWeight: 400,
            },
          },
        },
        yAxis: {
          data: ["1000", "1500", "2000", "2500", "3000"],
          // 修改图表y轴颜色（splitLine）
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#8E95AA"],
              width: 1,
              type: "solid",
              opacity: 0.2,
            },
          },
          // 修改图标y轴底线颜色
          axisLine: {
            lineStyle: {
              color: "#8E95AA",
              opacity: 0.2,
            },
          },
          // 修改y轴字体配置
          axisLabel: {
            textStyle: {
              show: true,
              fontFamily: "Source Han Sans CN-Regular, Source Han Sans CN",
              color: "#8E95AA",
              fontSize: "12",
              fontWeight: 400,
            },
          },
          type: "value",
          axisTick: {
            show: false, //y轴线消失
          },
        },
        series: [
          {
            name: "销量",
            type: "bar",
            data: [
              1000, 2000, 3000, 3000, 2500, 14000, 3200, 1000, 2000, 2900, 1000,
              3500,
            ],
            barMaxWidth: 20,
            // showBackground
            itemStyle: {
              color: "#65AFFF",
              // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   {
              //     offset: 1,
              //     color: "#65AFFF",
              //   },
              // {
              //   offset: 0,
              //   color: "#16C2DD ",
              // },
              // ]),
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.echas {
  width: 100%;
  height: 100%;
  .heda {
    width: 100%;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    position: absolute;
    top: 0;
    left: 0;
    img {
      width: 150px;
      height: 28.3px;
      position: absolute;
      top: 30%;
      left: 2%;
      cursor: pointer;
    }
  }
  .hon {
    width: 98%;
    margin-left: 1%;
    height: 180px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 90px;
    bottom: 0;
    .kong {
      width: 23%;
      min-width: 318px;
      height: 180px;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      &.active {
        background: rgb(209, 210, 212);
      }
    }
  }
  .bute {
    width: 98%;
    margin-left: 1%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 290px;
    bottom: 0;
    .left {
      width: 74.8%;
      height: 100%;
      // border: 1px solid #18cc9c;
      .cess {
        // display: block;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .ecsxu {
          width: 48%;
          height: 45%;
          margin: 10px;
          border-radius: 10px;
          opacity: 0.75;
          background: #ffffff;
          box-shadow: 0px 0px 10px 1px rgba(44, 55, 55, 0.07);
          border-radius: 10px 10px 10px 10px;
        }
      }
    }
    .right {
      width: 23%;
      height: 100%;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }
  }
}
.kong {
  overflow: hidden;
  position: relative;
  div {
    width: 261px;
    font-size: 18px;
    color: #999999;
    margin-left: 20px;
    margin-top: 10px;
  }
  p {
    height: 20px;
    font-size: 20px;
    font-weight: 500;
    color: #666666;
    margin: 18px 0 0 20px;
  }
  img {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}

.right {
  .tozi {
    width: 40px;
    height: 20px;
    font-size: 20px;
    font-weight: 500;
    color: #666666;
    margin: 20px 0 20px 20px;
  }
}
.xinxi {
  width: 100%;
  margin-top: 20px;
  display: flex;
  span {
    display: block;
  }
  .xuhao {
    width: 5%;
    height: 14px;
    font-size: 14px;
    color: #8e95aa;
    margin-left: 20px;
  }
  .orde {
    width: 20%;
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #5b606e;
    text-align: left;
  }
  .mix {
    width: calc(65% - 20px);
    height: 14px;
    font-size: 14px;
    color: #8e95aa;
    text-align: right;
  }
}
</style>
<style lang="less" scoped>
.TaxService {
  border: 2px solid #adc6ff !important;
  background: #f1f5fe !important;
  cursor: pointer !important;
}
.ImagingServices {
  border: 2px solid #a7d7ec !important;
  background: #ecf9ff !important;
  cursor: pointer !important;
}
.ArchivesService {
  border: 2px solid #f5c596 !important;
  background: #fff6ed !important;
  cursor: pointer !important;
}
.CheckService {
  border: 2px solid #f3b2b2 !important;
  background: #ffefef !important;
  cursor: pointer !important;
}
.boxSty {
  width: 23%;
  height: 180px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 2px solid #ccc;
  background: #ccc;
  cursor: not-allowed;
  .textTitle {
    width: 100%;
    height: 20px;
    margin-top: 18px;
    margin-left: 20px;
    p {
      font-size: 20px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #333333;
    }
  }
  .TextImg {
    display: flex;
    justify-content: space-between;
    position: relative;
    .imgText {
      width: 280px;
      height: 84px;
      margin-top: 10px;
      margin-left: 20px;
      p {
        font-size: 18px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 8px;
      }
    }
    .imgSty {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 30px;
      right: 20px;
    }
  }
}
</style>
