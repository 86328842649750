<template>
  <div id="about" class="about-us">
    <h2>关于慧财</h2>
    <div class="about-content clearfix">
      <div class="info">
        <div class="dm">
          北京慧财信息技术有限公司(简称:慧财)是面向
          未来的智慧财税服务商。 我们志在利用新理
          念、新技术帮助企业实现数字化财务转型，促
          进企业 的财务组织更高效的运营，更智慧的
          管理，释放更多的价值。 慧财团队拥有深耕
          行业十余年的资深人才，深刻理解财务运营管
          理现状 及未来趋势，我们可以为客户提供的
          服务包括：集团影像和档案管理、 RPA财务
          机器人、应付共享AP自动化管理、应收共享
          AR自动化管理、智慧 税务管理等服务。 我们
          的核心团队成员来自全球知名咨询公司及本地
          化特色的财务及税负信息 化知名企业。总部
          位于北京，目前在上海、成都、长沙、深圳均
          设有办事处， 在武汉设立分公司暨全国研发
          中心，员工人数超过50人。
        </div>
      </div>
      <div class="map">
        <img v-lazy="imgUrl" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgUrl: require("../assets/img/tp/222.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
.about-us {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  h2 {
    // width:123px;
    // height:29px;
    width: 80%;
    height: 65px;
    margin: 0 auto;
    border-bottom: 1px solid #999;
    font-size: 30px;
    font-family: "Source Han Sans CN";
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 21px;
    margin-top: 60px;
  }
  .about-content {
    width: 80%;
    margin: 0 auto;
    padding: 45px 0px 71px 0px;
    display: flex;
    // line-height: 50px;
    // justify-content: center;
    justify-content: space-between;
    // flex: 1;
    // align-items: center;
    .info {
      width: 40%;
      // float: left;
      .dm {
        // width: 395px;
        // height: 538px;
        // font-size: 18px;
        // //   font-family: PingFang SC-Regular, PingFang SC;
        // font-weight: 400;
        // color: #999999;
        // margin-right: 50px;
        // letter-spacing: 1px;

        width: 600px;
        height: 538px;
        font-size: 18px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-right: 150px;
        letter-spacing: 4px;
        line-height: 40px;
      }
      //   margin: 0 auto;
      // text-align: center;
      //   padding-bottom: 5px;
      //   border-bottom: 5px solid#000;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
    .map {
      //   margin-top: 75px;
      // float: left;
      height: 538px;
      //   width: auto;
      flex: 1;
      // float: right;
      margin-right: 0;
      img {
        // max-width: 100%;
        width: 857px;
        // height: auto;
        height: 538px;
      }
    }
  }
}
</style>