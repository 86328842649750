<template>
  <div class="agreementpreview" id="tabbarBoxBody">
    <p class="textStyle">
      <span class="title"> 慧财平台服务协议 </span>
    </p>
    <p class="testStyle rightStyle">
      <span style="word-break: break-all; font-size: 16px">
        最新版本生效日期：2019年08月19日
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="PromptTerms">提示条款</span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p class="testStyle">
      <span class="container">
        欢迎您与各淘宝平台经营者（详见定义条款）共同签署本《淘宝平台服务协议》（下称“本协议”）并使用淘宝平台服务！
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="container">
        本协议为《淘宝服务协议》修订版本，自本协议发布之日起，淘宝平台各处所称“淘宝服务协议”均指本协议。
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="container">
        各服务条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span>
        <span class="blackText">【审慎阅读】 </span>
        <span>
          您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。
          <span class="blackText Textunderline">
            请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。</span
          >
          如您对协议有任何疑问，可向淘宝平台客服咨询。
        </span>
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p class="testStyle">
      <span>
        <span class="blackText">【签约动作】</span>
        <span>
          当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与淘宝达成一致，成为淘宝平台“用户”。
          <span class="blackText Textunderline">
            阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。
          </span>
        </span>
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <!-- 一、 定义 -->
    <p class="HeadingStyles titlesStyle">
      <span>一、 定义</span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span>
        <span class="titlesStyle">支付宝公司 </span
        ><span class="normol">
          ：指提供支付宝服务的主体支付宝（中国）网络技术有限公司。
        </span>
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span>
        <span class="titlesStyle"> 关联公司 </span
        ><span class="normol">
          ：指阿里巴巴集团控股有限公司（Alibaba Group Holding
          Limited）最新上市公司年报披露的淘宝平台服务提供者的关联公司。
        </span>
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <!-- 二、 协议范围 -->
    <p class="HeadingStyles titlesStyle">
      <span>二、 协议范围</span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="titlesStyle">1.签约主体 </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p class="HeadingStyles">
      <span>
        <span class="titlesStyle">【平等主体】</span>
        <span class="normol">
          本协议由您与淘宝平台经营者共同缔结，本协议对您与淘宝平台经营者均具有合同效力。</span
        >
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="titlesStyle">2.补充协议 </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p class="HeadingStyles">
      <span>
        <span class="titlesStyle">【平等主体】</span>
        <span class="normol">
          本协议由您与淘宝平台经营者共同缔结，本协议对您与淘宝平台经营者均具有合同效力。</span
        >
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>

    <!-- 三、 账户注册与使用 -->
    <p class="HeadingStyles titlesStyle">
      <span>三、 账户注册与使用</span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="titlesStyle">1.用户资格 </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="normol"
        >您确认，在您开始注册程序使用淘宝平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。<span
          class="blackText Textunderline"
          >若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。</span
        >
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="titlesStyle">2.账户说明 </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="normol"
        ><span class="blackText">【账户获得】</span
        ><span class="normol"
          >当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可获得淘宝平台账户并成为淘宝平台用户。</span
        >
      </span>
    </p>
    <p>
      <span class="normol"
        >淘宝平台只允许每位用户使用一个淘宝平台账户。如有证据证明或淘宝根据淘宝平台规则判断您存在不当注册或不当使用多个淘宝平台账户的情形，淘宝平台可采取冻结或关闭账户、取消订单、拒绝提供服务等措施，如给淘宝平台及相关方造成损失的，您还应承担赔偿责任。</span
      >
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="normol"
        ><span class="blackText">【账户使用】</span
        ><span class="normol">
          您有权使用您设置或确认的淘宝会员名、邮箱、手机号码（以下简称“账户名称”）及您设置的密码（账户名称及密码合称“账户”）登录淘宝平台。
          您有权使用您的淘宝平台账户登录阿里平台，但您的淘宝平台账户于2013年3月18日前注册或者您注册淘宝平台账户时所使用的邮箱或手机号码已在阿里平台注册或使用过的除外。
        </span>
      </span>
    </p>
    <p>
      <span class="normol"
        >由于您的淘宝平台账户关联您的个人信息及淘宝平台商业信息，您的淘宝平台账户仅限您本人使用。未经淘宝平台同意，您直接或间接授权第三方使用您淘宝平台账户或获取您账户项下信息的行为无效。如淘宝根据淘宝平台规则中约定的违约认定程序及标准判断您淘宝平台账户的使用可能危及您的账户安全及/或淘宝平台信息安全的，淘宝平台可拒绝提供相应服务或终止本协议。</span
      >
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <!-- 四、 淘宝平台服务及规范 -->
    <p class="HeadingStyles titlesStyle">
      <span>四、淘宝平台服务及规范</span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="blackText">【服务概况】</span>
      <span class="normol">
        您有权在淘宝平台上享受商品及/或服务的浏览、收藏、购买与评价、交易争议处理、信息交流如问答及分享等服务。淘宝提供的服务内容众多，具体您可登录淘宝平台浏览。
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="blackText">1.商品及/或服务的浏览、收藏</span>
    </p>
    <p>
      <span class="normol"
        >在您浏览我们网站或客户端的过程中，淘宝为您提供了信息分类、关键词检索、筛选、收藏及关注等功能，以更好地匹配您的需求。您可以对您感兴趣的商品及/或服务进行收藏、添加至购物车，关注您所感兴趣的店铺/品牌等。</span
      >
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="blackText">2.商品及/或服务的购买与评价</span>
    </p>
    <p>
      <span class="blackText">【商品及/或服务的购买】 </span>
      <span class="normol">
        在您浏览我们网站或客户端的过程中，淘宝为您提供了信息分类、关键词检索、筛选、收藏及关注等功能，以更好地匹配您的需求。您可以对您感兴趣的商品及/或服务进行收藏、添加至购物车，关注您所感兴趣的店铺/品牌等。
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>

    <!-- 九、 协议的终止 -->
    <p class="HeadingStyles titlesStyle">
      <span>五、 协议的终止</span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="titlesStyle">1.终止的情形 </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="blackText">【用户发起的终止】</span>
      <span class="normol"> 您有权通过以下任一方式终止本协议： </span>
    </p>
    <p>
      <span class="normol">
        （一）在满足淘宝平台公示的账户注销条件时您通过网站自助服务注销您的账户的；
      </span>
    </p>
    <p>
      <span class="normol">
        （二）变更事项生效前您停止使用并明示不愿接受变更事项的；
      </span>
    </p>
    <p>
      <span class="normol">
        （三）您明示不愿继续使用淘宝平台服务，且符合淘宝平台终止条件的。
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="blackText">【淘宝发起的终止】</span>
      <span class="normol">
        出现以下情况时，淘宝可以本协议第八条的所列的方式通知您终止本协议：
      </span>
    </p>
    <p>
      <span class="normol"> （一）您违反本协议约定，淘宝依据违约条款终止本协议的； </span>
    </p>
    <p>
      <span class="normol">
        （二）您盗用他人账户、发布违禁信息、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为，淘宝依据淘宝平台规则对您的账户予以查封的；
      </span>
    </p>
    <p>
      <span class="normol">
        （三）除上述情形外，因您多次违反淘宝平台规则相关规定且情节严重，淘宝依据淘宝平台规则对您的账户予以查封的；
      </span>
    </p>

    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>

    <p>
      <span class="titlesStyle">2.协议终止后的处理 </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="blackText">【淘宝权利】</span>
      <span class="normol"> 本协议终止后，淘宝仍享有下列权利： </span>
    </p>

    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="PromptTerms"
        >【用户信息披露】<span class="Textunderline"
          >本协议终止后，除法律有明确规定外，淘宝无义务向您或您指定的第三方披露您账户中的任何信息。</span
        >
      </span>
    </p>

    <p>
      <span class="normol">
        （一）继续保存您留存于淘宝平台的本协议第五条所列的各类信息；
      </span>
    </p>
    <p>
      <span class="normol">
        （二）对于您过往的违约行为，淘宝仍可依据本协议向您追究违约责任。
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p class="diyStyle">
      <span class="PromptTerms"
        >【交易处理】<span class="Textunderline"
          >本协议终止后，对于您在本协议存续期间产生的交易订单，淘宝可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。</span
        >
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>

    <!-- 十、 法律适用、管辖与其他 -->
    <p class="HeadingStyles titlesStyle">
      <span>六、协议的终止</span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="PromptTerms"
        >【法律适用】<span class="Textunderline"
          >本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。</span
        >
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="PromptTerms"
        >【管辖】<span class="Textunderline"
          >您因使用淘宝平台服务所产生及与淘宝平台服务有关的争议，由淘宝与您协商解决。协商不成时，任何一方均可向被告所在地有管辖权的人民法院提起诉讼。</span
        >
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <span class="PromptTerms"
        >【可分性】<span class="normol"
          >本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</span
        >
      </span>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <el-divider></el-divider>
    <div class="checkboxStyle" id="tabbarBox">
      <!-- <el-checkbox v-model="value" @change="change"> </el-checkbox>
      <p>已阅读完且同意遵守慧财平台服务协议</p> -->
      <el-button type="success" @click="change" :disabled="disabledStatus"
        >已阅读完且同意遵守慧财平台服务协议</el-button
      >
    </div>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
    <p>
      <strong><span style="font-size: 14px">&nbsp;</span></strong>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: false,
      tabbarBoxFixed: "",
      disabledStatus: true,
    };
  },
  created() {
    document.title = "慧财平台服务协议";
  },
  mounted() {
    // 向页面添加股东事件
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    //离开这个界面之后，删除滚动事件，不然容易除bug
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    change() {
      this.value = true;
      // console.log(this.value);
      this.closeWebPage();
      window.localStorage.setItem("ifAgree", this.value);
    },
    // 关闭页签
    closeWebPage() {
      if (navigator.userAgent.indexOf("MSIE") > 0) {
        if (navigator.userAgent.indexOf("MSIE 6.0") > 0) {
          window.opener = null;
          window.close();
        } else {
          window.open("", "_top");
          window.top.close();
        }
      } else if (
        navigator.userAgent.indexOf("Firefox") > 0 ||
        navigator.userAgent.indexOf("Chrome") > 0
      ) {
        window.location.href = "about:blank";
        window.close();
      } else {
        window.opener = null;
        window.open("", "_self");
        window.close();
      }
    },
    //滚动事件
    // handleScroll() {
    //   var scrollTop =
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop;
    //   var offsetTop = document.querySelector("#tabbarBox").offsetTop; // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
    //   var offscrollTop = document.querySelector("#tabbarBoxBody").scrollTop; // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
    //   console.log(`获取所需元素到文档顶部的距离`);
    //   console.log(offsetTop);
    //   console.log(offscrollTop);
    //   // if (scrollTop > offsetTop) {
    //   //   this.tabbarBoxFixed = true;
    //   // } else {
    //   //   this.tabbarBoxFixed = false;
    //   // }
    // },
    handleScroll() {
      //scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      // console.log(scrollTop);
      // console.log(windowHeight);
      // console.log(`滚动条滚动时，距离顶部的距离 + 可视区的高度`);
      // console.log(scrollTop + windowHeight);
      // console.log(`滚动条的总高度`);
      // console.log(scrollHeight);

      if (scrollTop + windowHeight > scrollHeight - 100) {
        // console.log("到了底部");
        this.disabledStatus = false;
      } else {
        // console.log("没有到底部");
      }

      if (document.hidden !== undefined) {
        document.addEventListener("visibilitychange", () => {
          if (document.hidden) {
            let routeUrl = this.$router.resolve({
              path: "/serviceAgreement",
            });
            window.open(routeUrl.href);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.agreementpreview {
  display: block;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  margin: 20px auto;
  p {
    margin: 5px 0;
    line-height: 1.5;
  }
  .textStyle {
    margin-top: 5px;
    margin-bottom: 5px;
    word-break: break-all;
    font-family: 宋体, SimSun;
    white-space: normal;
    text-align: center;
    background-image: initial;
    background-position: initial;
    background-size: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
  }
  .rightStyle {
    margin: 0 13px 0 0;
    font-size: medium;
    caret-color: rgb(0, 0, 0);
    color: rgb(0, 0, 0);
    white-space: normal;
    text-align: right;
    word-break: break-all;
    font-family: 微软雅黑, "Microsoft YaHei", 宋体, SimSun, 黑体, SimHei;
    font-size: 16px;
    margin: 0px !important;
  }
  .title {
    font-weight: 700;
    padding: 0px;
    word-break: break-all;
    font-size: 24px;
    margin: 0px !important;
  }
  .PromptTerms {
    font-family: 微软雅黑, "Microsoft YaHei", 宋体, SimSun, 黑体, SimHei;
    font-weight: 700;
    padding: 0px;
    word-break: break-all;
    font-size: 16px;
    margin: 0px !important;
  }
  .container {
    padding: 0px;
    word-break: break-all;
    font-family: 微软雅黑, "Microsoft YaHei", 宋体, SimSun, 黑体, SimHei;
    font-size: 16px;
    margin: 0px !important;
  }
  .blackText {
    font-weight: 700;
    padding: 0px;
    word-break: break-all;
    font-family: 微软雅黑, "Microsoft YaHei", 宋体, SimSun, 黑体, SimHei;
    margin: 0px !important;
  }
  //   标题样式
  .HeadingStyles {
    margin-top: 5px;
    margin-bottom: 5px;
    word-break: break-all;
    font-family: 宋体, SimSun;
    white-space: normal;
    background-image: initial;
    background-position: initial;
    background-size: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
  }
  .titlesStyle {
    font-weight: 700;
    padding: 0px;
    word-break: break-all;
    font-family: 微软雅黑, "Microsoft YaHei", 宋体, SimSun, 黑体, SimHei;
    margin: 0px !important;
  }
  .normol {
    padding: 0px;
    word-break: break-all;
    font-family: 微软雅黑, "Microsoft YaHei", 宋体, SimSun, 黑体, SimHei;
    margin: 0px !important;
  }
  .Textunderline {
    font-family: 微软雅黑, "Microsoft YaHei", 宋体, SimSun, 黑体, SimHei;
    font-weight: 700;
    padding: 0px;
    word-break: break-all;
    text-decoration-line: underline;
    font-size: 16px;
    margin: 0px !important;
  }
  .checkboxStyle {
    position: fixed;
    bottom: 0.5%;
    left: 45%;
    display: flex;
    justify-content: center;
    height: 45px;
    line-height: 33px;
    p {
      margin-left: 0.5%;
    }
  }
}
</style>
