<template>
  <div v-lazy:background-image="imgUrl" class="time-axis">
    <div class="time-content">
      <div class="top">
        <div class="left">
          <h4>《企业会计信息化工作规范》</h4>
          <p>
            提出实行会计工作集中的企业及其分支机构，应为外部
            会计监督机构调阅异地会计资料提供必要条件；企业
            内部生成的会计凭证、账簿、辅助性会计资料，在满足
            相关条件情况下可不输出纸面资料。
          </p>
        </div>
        <div class="right">
          <h4>《第二批电子档案管理试点工作开展》</h4>
          <p>
            国家档案局召开第二批试点单位的试点方案评审会，对
            继续扎实试点工作提出要求；新形势下，试点工作继续
            探索三维电子文件、电子发票、电子证照等的归档问题。
          </p>
        </div>
      </div>
      <div class="center">
        <!-- <img v-lazy="jcnf_bg" alt=""> -->
        <i class="year">2014</i>
        <i class="year">2016</i>
        <i class="year">2018</i>
        <i class="year">2019</i>
      </div>
      <div class="bottom">
        <div class="left">
          <h4>《会计档案管理办法》</h4>
          <p>
            规定单位可以利用计算机、网络通信等技术
            手段管理会计档案；单位从外部接收的附有
            符合《中华人民共和国电子签名法》规定电子
            签名的电子资料，以及满足相关条件的内部
            形成的归档范围内电子会计资料，可仅以电子
            形式保存，形成电子会计档案。
          </p>
        </div>
        <div class="right">
          <h4>《国务院明确政务服务电子档案法律效力》</h4>
          <p>
            规定单位可以利用计算机、网络通信等技术
            手段管理会计档案；单位从外部接收的附有
            符合《中华人民共和国电子签名法》规定电子
            签名的电子资料，以及满足相关条件的内部
            形成的归档范围内电子会计资料，可仅以
            电子形式保存，形成电子会计档案。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //   jcnf_bg: require("../assets/img/tp/16833.png"),
      imgUrl: require("../assets/img/tp/16833.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
.time-axis {
  width: 100%;
  height: 600px;
  background: url("../assets/img/tp/16833.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  color: #fff;
  .time-content {
    width: 100%;
    margin: 0 auto;
    padding-top: 295px;
    h4 {
      text-align: center;
      font-size: 16px;
      font-family: "PingFang SC";
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
    }
    p {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 21px;
      opacity: 0.6;
    }
    .top {
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 80%;
      .left {
        position: absolute;
        left: 12.5%;
        top: -250px;
        width: 25%;
        height: 250px;
        background: url(./../assets/img/tp/4ge.png) no-repeat;
        background-size: 100% 100%;
        padding-bottom: 2px;
        // margin-left: 61px;
        // background-color: rgba(97, 190, 245, 0.5);
        h4 {
          width: 100%;
          white-space: normal;
          height: 28px;
          font-size: 20px;
          margin: 0 auto;
          margin-top: 30px;
          color: #333333;
          letter-spacing: 1px;
          font-family: PingFang SC-Bold, PingFang SC;
        }
        p {
          width: 250px;
          height: 170px;
          font-size: 14px;
          margin: 0 auto;
          margin-top: 10px;
          color: #666666;
          letter-spacing: 1px;
        }
      }
      .right {
        height: 250px;
        position: absolute;
        // background-color: rgba(97, 190, 245, 0.5);
        background: url(./../assets/img/tp/4ge.png) no-repeat;
        background-size: 100% 100%;
        left: 62.5%;
        top: -250px;
        width: 25%;
        padding-bottom: 2px;
        h4 {
          width: 100%;
          white-space: normal;
          height: 56px;
          font-size: 20px;
          margin: 0 auto;
          margin-top: 30px;
          color: #333333;
          letter-spacing: 1px;
          font-family: PingFang SC-Bold, PingFang SC;
        }
        p {
          width: 250px;
          margin: 0 auto;
          height: 170px;
          font-size: 14px;
          margin-top: 10px;
          color: #666666;
          letter-spacing: 1px;
        }
      }
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      position: relative;
      width: 80%;
      .left {
        // background-color: rgba(97, 190, 245, 0.5);
        background: url(./../assets/img/tp/4ge.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: 37.5%;
        height: 250px;
        top: -2px;
        width: 25%;
        margin-right: 265px;
        padding-top: 2px;
        h4 {
          width: 100%;
          white-space: normal;
          height: 28px;
          font-size: 20px;
          margin: 0 auto;
          margin-top: 30px;
          color: #333333;
          letter-spacing: 1px;
          font-family: PingFang SC-Bold, PingFang SC;
        }
        p {
          width: 250px;
          height: 146px;
          font-size: 14px;
          margin: 0 auto;
          margin-top: 10px;
          color: #666666;
          letter-spacing: 1px;
        }
      }
      .right {
        // background-color: rgba(97, 190, 245, 0.5);
        background: url(./../assets/img/tp/4ge.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: 87.5%;
        top: -2px;
        height: 250px;
        width: 25%;
        margin-right: 23px;
        padding-top: 2px;
        // text-align: center;
        h4 {
          margin: 0 auto;
          width: 100%;
          white-space: normal;
          font-size: 20px;
          margin-top: 50px;
          margin-top: 30px;
          color: #333333;
          letter-spacing: 1px;
          font-family: PingFang SC-Bold, PingFang SC;
        }
        p {
          margin: 0 auto;
          margin-top: 10px;
          width: 250px;
          height: 140px;
          margin-top: 10px;
          color: #666666;
          letter-spacing: 1px;
        }
      }
    }
    .center {
      position: relative;
      width: 80%;
      margin: 0 auto;
      background: #00edff;
      height: 5px;
      .year {
        position: absolute;
        font-size: 24px;
        font-family: "SF Pro Text";
        font-weight: bold;
        color: #00edff;
        font-style: normal;
        left: 9%;
        bottom: -30px;
        &:nth-child(2) {
          left: 35.5%;
          bottom: 7px;
        }
        &:nth-child(3) {
          left: 60.5%;
          bottom: -30px;
        }
        &:nth-child(4) {
          left: 85.5%;
          bottom: 7px;
        }
        // &:nth-child(5){
        //     right: 12.5%;
        //     bottom: 30px;
        // }
      }
    }
  }
}
</style>
