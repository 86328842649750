<template>
  <div class="solution">
    <h2>慧财的解决方案</h2>
    <div>
      <img v-lazy="solution" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      solution: require("../assets/img/bg/solut.png"),
    };
  },
};
</script>
<style lang="scss" scoped>
.solution {
  width: 100%;
  height: 100%;
  // width: 1300px;
  //   height: 839.5px
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  h2 {
    //   display: block;
    width: 100%;
    height: 33px;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    float: left;
    // margin-left: 0;
    // padding-left: 0;
  }
  div {
    text-align: center;
    img {
      width: 1300px;
      height: 839.5px;
      //   margin: 0 auto;
    }
  }
}
</style>
