<template>
  <footer class="bottom-footer">
    <div class="bottom-container">
      <div class="top">
        <div class="left">
          <img v-lazy="logo" alt="" style="margin-top: 75px;">
        </div>
        <div class="center">
          <div class="title">联系我们</div>
          <div class="contract">
            <div class="item">
              <img class="telphone" v-lazy="telphone" alt="">
              <span>4008-533-556</span>
            </div>
            <div class="item">
              <img class="email" v-lazy="email" alt="">
              <span>kefu@huifai.com</span>
            </div>
            <div class="item">
              <img class="location" v-lazy="location" alt="">
              <div style="text-align: left;">
                <p>北京：北京市大兴区宏业路9号院4号楼8层806</p>
                <p>武汉：武汉市洪山区光谷总部时代4栋502</p>
              </div>
            </div>
          </div>
        </div>
        <!--                <div class="right">-->
        <!--                    <div class="title">关注我们</div>-->
        <!--                    <div>-->
        <!--                        <img class="code" v-lazy="qrcode" alt="">-->
        <!--                    </div>-->
        <!--                </div>-->
      </div>
      <div class="divider"></div>
      <div class="bottom">
        备案号： <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备19002643号</a>
      </div>
    </div>
  </footer>
</template>

<script>
    export default {
        data () {
            return {
                logo:require('../assets/img/bg/logo2.png'),
                qrcode:require('../assets/img/bg/qrcode.png'),
                location:require('../assets/img/bg/location.png'),
                email:require('../assets/img/bg/email.png'),
                telphone:require('../assets/img/bg/telphone.png'),
            }
        }
    }
</script>

<style lang="scss" scoped>
.bottom-footer {
  width: 100%;
  height: 540px;
  background: rgba(34, 34, 34, 1);
  color: #fff;
  .bottom-container {
    padding: 114px 15px 0 15px;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .top {
      display: flex;
      flex: 1;
      justify-content: space-around;
      .left {
        padding: 0 53px;
        img {
          width: 205px;
          height: 41px;
        }
      }
      .center {
        // width: 180px;
        .title {
          font-size: 24px;
          text-align: left;
          margin-bottom: 37px;
        }
        .contract {
          .item {
            display: flex;
            font-size: 14px;
            align-items: center;
            letter-spacing: 1px;
            margin-bottom: 20px;
            img {
              width: 20px;
              height: 20px;
              margin-right: 10px;
            }
          }
        }
      }
      .right {
        .title {
          font-size: 24px;
          height: 26px;
          line-height: 26px;
          margin-bottom: 32px;
          letter-spacing: 1px;
        }
        .code {
          width: 210px;
          height: 210px;
        }
      }
    }
    .divider {
      width: 100%;
      height: 1px;
      background: rgba(74, 74, 74, 1);
    }
    .bottom {
      height: 68px;
      line-height: 68px;
      font-size: 14px;
      font-family: "Source Han Sans CN";
      font-weight: 400;
      // color:rgba(255,255,255,1);
      // opacity:0.26;
      color: #ccc;
      // color: #ffffff42;
      i {
        font-style: normal;
        cursor: pointer;
        color: #ccc;
        &:hover {
          color: #fff;
        }
      }
    }
  }
}
</style>