<template>
  <div id="build-container" class="build-container">
    <div class="build-content">
      <p class="hh">集团智慧财务建设方向</p>
      <div class="box clearfix">
        <!-- <div :class="['item',{active:item.isActive}]" v-for="(item,index) in list" :key="index" @click="handleClick(item)">
                    <div class="info">
                        <h3 class="title">{{item.title}}</h3>
                        <p  :class="['desc',{active:item.isActive}]" >{{item.desc}}</p>
                    </div>
                </div> -->
        <div
          :class="['hezhi', { active: isActive == 1 }]"
          @mouseover="mouseOver(1)"
          @mouseleave="isActive = 0"
        >
          <div class="title">大平台</div>
          <div class="desc">
            统一的基础应用平台、即插即用式的功能扩展、各个子系统实现数据互通等
          </div>
        </div>
        <div
          :class="['hezhi', { active: isActive == 2 }]"
          @mouseover="mouseOver(2)"
          @mouseleave="isActive = 0"
        >
          <div class="title">大数据</div>
          <div class="desc">
            流程嵌入式分析、可视化数据分析运营数据、员工行为分析
          </div>
        </div>
        <div
          :class="['hezhi', { active: isActive == 3 }]"
          @mouseover="mouseOver(3)"
          @mouseleave="isActive = 0"
        >
          <div class="title">高智能</div>
          <div class="desc">业务风险监控、自动化数据校验、业务提醒预警</div>
        </div>
        <div
          :class="['hezhi', { active: isActive == 4 }]"
          @mouseover="mouseOver(4)"
          @mouseleave="isActive = 0"
        >
          <div class="title">重体验</div>
          <div class="desc">界面友好、易用、稳定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dpt_nor from "../assets/img/build/dpt_nor.png";
import dpt_pre from "../assets/img/build/dpt_pre.png";
import dsj_nor from "../assets/img/build/dsj_nor.png";
import dsj_pre from "../assets/img/build/dsj_pre.png";
import gzn_nor from "../assets/img/build/gzn_nor.png";
import gzn_pre from "../assets/img/build/gzn_pre.png";
import zty_nor from "../assets/img/build/zty_nor.png";
import zty_pre from "../assets/img/build/zty_pre.png";
export default {
  data() {
    return {
      isActive: 0,
      list: [
        {
          url: [dpt_nor, dpt_pre],
          isActive: true,
          title: "大平台",
          desc: "统一的基础应用平台、即插即用式的功能扩展、各个子系统实现数据互通等",
        },
        {
          url: [dsj_nor, dsj_pre],
          isActive: false,
          title: "大数据",
          desc: "流程嵌入式分析、可视化数据分析运营数据、员工行为分析",
        },
        {
          url: [gzn_nor, gzn_pre],
          isActive: false,
          title: "高智能",
          desc: "业务风险监控、自动化数据校验、业务提醒预警",
        },
        {
          url: [zty_nor, zty_pre],
          isActive: false,
          title: "重体验",
          desc: "界面友好、易用、稳定",
        },
      ],
      lastAcitve: {},
    };
  },
  methods: {
    // 移入事件
    mouseOver(id) {
      this.isActive = id;
    },
    handleClick(item) {
      this.lastAcitve.isActive = false;
      item.isActive = !item.isActive;
      this.lastAcitve = item;
    },
  },
  mounted() {
    this.lastAcitve = this.list[0];
  },
};
</script>

<style lang="scss" scoped>
.build-container {
  // background-color: #007AFF;
  margin-top: 200px;
  width: 100%;
  height: 600px;
  background: rgba(255, 255, 255, 1);
  padding-top: 81px;
  background: url("../assets/img/tp/16835.png") no-repeat;
  background-size: cover;
  // display: flex;
  .build-content {
    width: 80%;
    margin: 0 auto;
    // margin-top: 80px;
    .hh {
      margin-bottom: 50px;
      font-size: 36px;
      font-weight: 550;
      height: 75px;
      color: #333333;
      border-bottom: 1px solid #999999;
    }
    .box {
      width: 100%;
      display: flex;
      // align-items: center;
      //   justify-content: space-around;
      justify-content: center;
      // margin: 0 auto;
      .hezhi {
        margin: 0 auto;
        // width: 100%;
        // display: flex;
        // justify-content: center;
        // width: 310px;
        width: 22%;
        height: 310px;
        background: #fff;
        box-shadow: 0px 0px 6px 0px rgba(51, 51, 51, 0.11);
        // margin-right: 20px;
        .title {
          width: 90px;
          height: 42px;
          font-size: 30px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #007aff;
          margin: 0 auto;
          margin-top: 78px;
        }
        .desc {
          text-align: center;
          width: 250px;
          height: 75px;
          font-size: 18px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin: 0 auto;
          margin-top: 20px;
        }
        &.active {
          margin-top: -5px;
          box-shadow: 0px 0px 18px 5px rgba(40, 110, 252, 0.31);
          .title {
            color: #f29423;
          }
          .desc {
            color: #333333;
          }
        }
      }
      // .item{
      //     // float: left;
      //     width: 310px;
      //     height: 310px;
      //     text-align: center;
      //     background:rgba(255,255,255,1);
      //     box-shadow:0px 0px 6px 0px rgba(51,51,51,0.11);
      //     border-radius:4px;
      //     // margin-right: 100px;
      //     // margin: 0 20px 16px 0px;
      //     // margin: 0 20px;
      //     display: flex;
      //     // justify-content: space-between;
      //     // cursor: pointer;
      //     padding: 0;
      //     &.active{
      //         // background:rgba(44,83,210,1);
      //         margin-top: -5px;
      //         box-shadow:0px 0px 18px 5px rgba(40,110,252,0.31);
      //         border-radius:4px;
      //         // color: #333333;
      //         .title{
      //             color: #F29423;
      //         }
      //         .desc{
      //             color:#333333 ;
      //         }
      //     }
      //     &:nth-child(4){
      //         margin-right: 0px;
      //     }
      //     .title{
      //         color: #007AFF;
      //         font-size: 30px;
      //     }
      //     .info{
      //         // flex: 1;
      //         display: flex;
      //         flex-direction: column;
      //         justify-content: center;
      //         align-items: center;
      //         .title{
      //             font-size: 30px;
      //             margin: 0 auto;
      //         }
      //         .desc{
      //             margin: 0 auto;
      //            padding-right: 6px;
      //            margin-top: 0px;
      //            line-height: 20px;
      //            color:#999999  ;
      //            font-size: 18px;
      //            width: 250px;
      //            height: 70px;
      //            &.active{
      //              color:#333333 ;
      //            }
      //         }
      //     }
      // }
    }
  }
}
</style>
