<template>
  <div>
      <Login :isao="isao"></Login>
  </div>
</template>

<script>
import Login from '../components/Login/index.vue'
export default {
    name:'login',
    components: {
        'Login':Login,
    },
    data() {
        return {
            isao: true
        }
    },
    created() {
        if (this.$route.query.isao == 'true') {
            this.isao = true
        }
    }
}
</script>

<style>

</style>