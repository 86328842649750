<template>
  <div class="mode">
    <h3 class="title">传统模式在线交易的弊端</h3>
    <p class="statement">
      企业作为独立经营实体，与客户及供应商的交易行为各自独立。因此，即使企业内部信息化飞速发展，但企业间的交易过程
      依然存在着显著的问题：信息共享程度低、信息处理效率慢、自动化程度低、信息匹配
      难度大、资金周转慢等。<br />
      慧采平台通过SAAS云平台打通企业间的信息链，来实现企业间快速的交易、财务结算和对账，
      通过销项开票自动化、在线订单处理和结算、应付发票自动化处理等一系列方案，最终实现
      企业间的多元化业务服务和数据分析服务。
    </p>
    <div class="hezhi">
      <div
        :class="['box', { active: isActive == 1 }]"
        @mouseover="mouseOver(1)"
        @mouseleave="isActive = 0"
      >
        <div>
          <img src="../assets/img/tp/16615.png" />
        </div>
        <p class="tou">在线交易处理</p>
        <p class="but">
          采购订单和销售订单 交易数据确认繁琐传递 时效性差
          发货单与入库单难以对应 入库数据手动录入
        </p>
      </div>
      <div
        :class="['box', { active: isActive == 2 }]"
        @mouseover="mouseOver(2)"
        @mouseleave="isActive = 0"
      >
        <div><img src="../assets/img/tp/16250.png" /></div>
        <p class="tou">结算对账</p>
        <p class="but">
          线下对账繁琐 发票难以跟踪，退票量大 对账很难匹配明细 发票很难匹配订单
          结算确认过程几乎线下
        </p>
      </div>
      <div
        :class="['box', { active: isActive == 3 }]"
        @mouseover="mouseOver(3)"
        @mouseleave="isActive = 0"
      >
        <div><img src="../assets/img/tp/16576.png" /></div>
        <p class="tou">账款处理</p>
        <p class="but">
          付款计划执行干扰大 复卷状态不透明 回款进度无法 查询 供应链融资困难
        </p>
      </div>
      <div
        :class="['box', { active: isActive == 4 }]"
        @mouseover="mouseOver(4)"
        @mouseleave="isActive = 0"
      >
        <div style="height: 16%"><img src="../assets/img/tp/16819.png" /></div>
        <p class="tou">数据分析</p>
        <p class="but">
          供应商交易行为无法分析 销售单品周期数据无法匹配 资金效率分析不足
          采购商品无法数据分析
        </p>
      </div>
    </div>

    <!-- <div class="mode-box">
            <div class="mode-box-item"
            v-for="(item,index) in list"
            :key="index"
            >
                <div class="img">
                    <img v-lazy="item.url" alt="">
                </div>
                <h3 class="name">{{item.name}}</h3>
                <p class="desc" v-html="item.desc"></p>
            </div>
        </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      isActive: 0,
      list: [
        {
          url: require("../assets/img/mode/jycl_nor.png"),
          name: "在线交易处理",
          desc: `采购订单和销售订单<br/>交易数据确认繁琐传递
                    时效性差<br/>发货单与入库单难以对应<br/>入库数据手动录入`,
        },
        {
          url: require("../assets/img/mode/jsdz_nor.png"),
          name: "结算对账",
          desc: `线下对账繁琐<br/>发票难以跟踪<br/>
                    退票量大<br/>对账很难匹配明细<br/>发票很难匹配订单<br/>结算确认过程几乎线下`,
        },
        {
          url: require("../assets/img/mode/zkcl_nor.png"),
          name: "账款处理",
          desc: `付款计划执行干扰大<br/>复卷状态不透明<br/>回款进度无法
                    查询<br/>供应链融资困难`,
        },
        {
          url: require("../assets/img/mode/sjfx_nor.png"),
          name: "数据分析",
          desc: `供应商交易行为无法分析<br/>销售单品周期数据无法匹配<br/>
                    资金效率分析不足<br/>采购商品无法数据分析`,
        },
      ],
    };
  },
  methods: {
    mouseOver(id) {
      this.isActive = id;
    },
  },
};
</script>
<style lang="scss" scoped>
.mode {
  // padding: 24px 61px 44px 47px;
  .title {
    margin: 0;
    height: 20px;
    font-size: 24px;
    font-family: "Source Han Sans CN";
    font-weight: 550;
    color: #333;
    line-height: 21px;
  }
  .statement {
    margin: 29px 0 43px 0;
    font-size: 18px;
    font-family: "Source Han Sans CN";
    // font-weight:500;
    color: rgba(102, 102, 102, 1);
    line-height: 30px;
  }
  .hezhi {
    display: flex;
    justify-content: space-between;
    .box {
      width: 22%;
      height: 410px;
      text-align: center;
      border: 1px solid #999999;
      display: flex;
      flex-direction: column;
      &.active {
        margin-top: -20px;
        border-color: #0000;
        box-shadow: 0px 10px 20px 1px rgba(41, 122, 210, 0.5);
      }
      div {
        width: 20%;
        height: 13%;
        margin: auto;

        img {
          // margin-top: 34px;
        }
      }
      .tou {
        font-size: 24px;
        color: #007aff;
      }
      .but {
        margin: 0 auto;
        width: 226px;
        height: 138px;
        font-size: 18px;
        //   font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  // &-box{
  //     display: flex;
  //     justify-content: center;
  //     &-item{
  //         width:230px;
  //         height:311px;
  //         background:rgba(255,255,255,1);
  //         border:1px solid rgba(229,229,229,1);
  //         &:not(:last-child){
  //             border-right: none;
  //         }
  //         &:hover{
  //             cursor: pointer;
  //             background:rgba(255,255,255,1);
  //             border:1px solid rgba(44,83,210,1);
  //             box-shadow:0px 0px 12px 1px rgba(40,110,252,0.31);
  //         }
  //         .img{
  //             text-align: center;
  //             margin-top: 35px;
  //             height: 50px;
  //         }
  //         .name{
  //             margin-top: 26px;
  //             height:16px;
  //             font-size:16px;
  //             font-family:'Source Han Sans CN';
  //             font-weight:bold;
  //             color:rgba(51,51,51,1);
  //             // line-height:21px;
  //             text-align: center;
  //         }
  //         .desc{
  //             font-size:14px;
  //             font-family:'Source Han Sans CN';
  //             color:rgba(102,102,102,1);
  //             line-height:21px;
  //             text-align: center;
  //             letter-spacing: 1px;
  //         }
  //     }
  // }
}
</style>
