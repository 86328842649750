<template>
  <div id="image-file" class="image-file">
    <div class="image-content">
      <h2>智慧财务-电子档案影像</h2>
      <div class="box clearfix">
        <div class="feature">
          <!-- <h3 class="title">慧财档案的特点</h3> -->
          <p class="desc">
            随着信息技术的发展，企业大量的财务档案
            信息都向电子化转化，但仍然存在大量的实物类型档案。<br />
            如何有效的集中的管理集团型企业的电子+
            实物会计档案成为企业“信息资产”和档案
            管理非常重要的课题。慧财通过电子影像+
            电子档案管理的方式，实现了企业会计档案
            管理的革新，实现了真正意义上的电子档案管理。
          </p>
          <!-- <div class="button">
                        会计档案的整体架构
                    </div> -->
          <div class="imp">
            <img src="../assets/img/bg/framework.png" />
          </div>
        </div>
        <!-- <div class="map"> -->
        <!-- <img v-lazy="framework" alt=""> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import framework from '../assets/img/bg/framework.png'
export default {
  data() {
    return {
      // framework
    };
  },
};
</script>

<style lang="scss" scoped>
.image-file {
  width: 100%;
  //   height: 800px;
  background: rgba(255, 255, 255, 1);
  .image-content {
    width: 80%;
    margin: 0 auto;
    h2 {
      font-size: 30px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      height: 65px;
      border-bottom: 1px solid#9999;
      margin-top: 50px;
    }
    .box {
      .feature {
        // float: left;
        // width: 320px;
        margin-top: 20px;
        .title {
          font-size: 24px;
          // font-family:"Source Han Sans CN";
          font-weight: bold;
          color: rgba(102, 102, 102, 1);
          line-height: 21px;
        }
        .desc {
          height: 83px;
          font-size: 18px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
        .imp {
          width: 100%;
          img {
            display: block;
            margin: 0 auto;
            width: 1180px;
            // height: 1169px;
          }
          //   margin-bottom: 100px;
        }
        // .button{
        //     margin-top: 50px;
        //     width:200px;
        //     height:50px;
        //     font-weight: 500;
        //     line-height:50px;
        //     background:rgba(44,83,210,1);
        //     border-radius:4px;
        //     color: #fff;
        //     text-align: center;
        // }
      }
      // .map{
      //     // float: right;
      //     width:800px;
      //     height:480px;
      //     background:rgba(255,255,255,1);
      //     box-shadow:0px 0px 10px 0px rgba(51,51,51,0.11);
      //     border-radius:2px;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // }
    }
  }
}
</style>
