import fetch from './fetch'
/**
 * 测试get接口
 * @param {*} id 测试数据得id
 */

// 用户注册
export const registerUser = (params) => fetch('/apSupport/user/registerUser', params, "POST");
//用户登录 -- 获取token
export const postLogin = (params) => fetch('/apSupport/login', params, "POST");
// 获取用户信息
export const getHomeUserInfo = (params, token) => fetch('/apSupport/user/getHomeUserInfo', params, "POST", token);
// 获取全系统列表
export const getSystemSource = (params) => fetch('/apSupport/user/getSystemSource', params, "GET");
//用户登录 -- 获取token
export const postLogout = (params, token) => fetch('/apSupport/logout', params, "POST", token);
// 获取手机验证码
export const getphone = (params) => fetch('/apSupport/getMobileVerificationCode', params, 'GET');
// 注册
export const toregister = (params) => fetch('/apSupport/user/registerUser', params, 'POST');