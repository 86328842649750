<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {};
  },
  components: {},
  methods: {},
};
</script>

<style>
@import "./assets/css/base.css";
</style>
<style lang="scss">
#app{
  width: 100%;
  height: 100%;
}
.head-title {
  position: relative;
  &::after {
    position: absolute;
    bottom: -24px;
    left: calc(50% - 30px);
    content: "";
    display: inline-block;
    width: 60px;
    height: 3px;
    background: rgba(47, 88, 212, 1);
  }
}
</style>

