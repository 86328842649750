<template>
  <div class="backlogin">
    <div class="zhong">
      <div class="left">
        <div class="logo">
          <img src="../../assets/img/login/logo.png" />
        </div>
        <div class="doimg">
          <img src="../../assets/img/login/750.png" />
        </div>
        <div class="welcome">
          <div class="topz">欢迎登录</div>
          <div class="btomz">企业财务服务云。</div>
        </div>
      </div>
      <div class="right">
        <div class="rigaotu">
          <div v-show="!isTion && !showCompany">
            <div class="fontlogin" v-show="!isObervte">欢迎登录</div>
            <div class="fontlogin" v-show="isObervte">欢迎注册</div>
            <div class="fontbut" v-show="!isObervte">WELCOME TO LOGIN</div>
            <div class="fontbut" v-show="isObervte">WELCOME TO REGISTER</div>
          </div>
          <!-- 注册时候公司信息 -->
          <div v-show="showCompany" class="RegisterCompany">
            <div class="fontlogin">公司信息</div>
            <div class="Companyfontbut">COMPANY INFORMATION</div>
          </div>

          <div v-show="!isTion">
            <div v-show="!isObervte">
              <div class="loginto">
                <div
                  class="passlogin pointer"
                  :class="!islogn == true ? 'active' : ''"
                  @click="islogn = false"
                >
                  密码登录
                  <div :class="!islogn == true ? 'dodu' : ''"></div>
                </div>
                <div
                  class="yanlogin pointer"
                  :class="islogn == true ? 'active' : ''"
                  @click="islogn = true"
                >
                  验证码登录
                  <div :class="islogn == true ? 'dodu' : ''"></div>
                </div>
              </div>
              <div class="logink" v-show="!islogn">
                <div class="top">
                  <img src="../../assets/img/login/zh.png" />
                  <input
                    type="text"
                    v-model="userName"
                    placeholder="请输入您的手机号/邮箱"
                  />
                </div>
                <div class="butm">
                  <img src="../../assets/img/login/mm.png" />
                  <input
                    v-model="password"
                    show-password
                    type="password"
                    placeholder="请输入您的密码"
                  />
                </div>
                <div class="gxddd">
                  <div class="gxlogo">
                    <el-checkbox
                      v-model="LoginAgree"
                      class="pointer loginBtn"
                      @change="IfLoginAgree"
                    >
                    </el-checkbox>
                    <span style="color: #000">
                      已阅读并同意
                      <a
                        style="color: #0f67c5; cursor: pointer"
                        @click="toService"
                      >
                        《用户注册服务协议》
                      </a>
                    </span>
                    <div class="wdpass pointer">忘记密码?</div>
                  </div>
                </div>
                <div class="ljlogin" @click="toye">
                  <div>立即登入</div>
                </div>
              </div>
              <div class="logink" v-show="islogn">
                <div class="top">
                  <img src="../../assets/img/login/zh.png" />
                  <input
                    type="text"
                    v-model="setlogin"
                    placeholder="请输入您的手机号"
                  />
                  <div
                    class="yanz pointer"
                    v-show="this.countdown"
                    @click="stphone"
                  >
                    获取验证码
                  </div>
                  <div class="yanz pointer" v-show="!this.countdown">
                    {{ timing }}s
                  </div>
                </div>
                <div class="butm">
                  <img src="../../assets/img/login/mm.png" />
                  <input
                    v-model="setphone"
                    type="text"
                    placeholder="请输入您的验证码"
                  />
                </div>
                <div class="gxddd">
                  <div class="gxlogo">
                    <el-checkbox
                      v-model="LoginAgree"
                      class="pointer"
                      @change="IfLoginAgree"
                      >记住我的登入信息
                    </el-checkbox>
                  </div>
                </div>
                <div class="ljlogin" @click="yanlogin">
                  <div>立即登入</div>
                </div>
                <div class="sjbtm">
                  <div class="nozh">如果无法收到验证码短信，请尝试</div>
                  <div class="pointer">语音验证码</div>
                </div>
              </div>
              <div class="buttom">
                <div class="top">
                  <div class="he"></div>
                  <div class="geduo">更多登录方式</div>
                  <div class="he"></div>
                </div>
                <div class="ladp pointer">
                  <div>私有云登入</div>
                </div>
                <div class="zhuc">
                  <div class="nozh">还没有账号？</div>
                  <div @click="isObervte = true" class="pointer">免费注册</div>
                </div>
              </div>
            </div>

            <!-- 注册模块 -->
            <div v-show="isObervte && !showCompany" class="register">
              <div class="top">
                <input
                  type="text"
                  v-model="registerDto.phone"
                  placeholder="请输入您的注册手机号/邮箱"
                />
              </div>
              <div class="top">
                <input
                  type="text"
                  v-model="registerDto.verificationCode"
                  placeholder="请填写收到的4位验证码"
                />
                <div
                  class="huoq pointer"
                  v-show="this.countdown"
                  @click="tophone"
                >
                  获取验证码
                </div>
                <div class="timeSty pointer" v-show="!this.countdown">
                  {{ timing }}s
                </div>
              </div>
              <div class="top">
                <input
                  type="text"
                  v-model="registerDto.userName"
                  placeholder="请输入用户名"
                />
              </div>
              <div class="top">
                <input
                  type="password"
                  v-model="registerDto.password"
                  placeholder="请输入10~20位密码"
                />
              </div>
              <div class="top">
                <input
                  type="password"
                  v-model="registerDto.password2"
                  placeholder="请再次确认密码"
                />
              </div>
              <div class="top">
                <input
                  type="text"
                  v-model="registerDto.orgName"
                  placeholder="请输入公司名称"
                />
              </div>
              <div class="top">
                <input
                  type="text"
                  v-model="registerDto.companyTaxNumber"
                  placeholder="请输入公司税号"
                />
              </div>
              <div class="top" v-if="ifShow">
                <input
                  type="text"
                  v-model="registerDto.srmUserName"
                  placeholder="请输入SRM账号"
                />
              </div>
              <div class="top" v-if="ifShow">
                <el-select
                  multiple
                  filterable
                  remote
                  reserve-keyword
                  v-model="registerDto.companyTaxNumberList"
                  placeholder="请输入对账公司税号"
                  :remote-method="companySelect"
                  class="companyStyle"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.companyTaxNumber"
                    :label="item.orgName"
                    :value="item.companyTaxNumber"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="services">
                <el-checkbox-group
                  v-model="checkedArray"
                  @change="checkedServices"
                >
                  <el-checkbox-button
                    v-for="item in services"
                    :label="item.sysSourceEname"
                    :key="item.sysSourceEname"
                    >{{ item.sysSourceName }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
              <div class="zcbtm">
                <div class="gxlogo" v-show="!ister">
                  <el-checkbox
                    v-model="RegisterAgree"
                    class="pointer"
                    @change="IfAgree"
                  >
                  </el-checkbox>
                  <span style="color: #000">
                    已阅读并同意
                    <a
                      style="color: #0f67c5; cursor: pointer"
                      @click="toService"
                    >
                      《用户注册服务协议》
                    </a>
                  </span>
                </div>
                <div class="gxlogo" v-show="ister">
                  <el-checkbox
                    v-model="RegisterAgree"
                    class="pointer"
                    @change="IfAgree"
                  >
                    同意《用户注册服务协议》
                  </el-checkbox>
                </div>
                <!-- register -->
                <div @click="addregister" class="ljlogin">
                  <div>注册</div>
                </div>
                <div class="sjbtm">
                  <div class="nozh">如果无法收到验证码短信，请尝试</div>
                  <div class="pointer">语音验证码</div>
                </div>
                <div class="tologin pointer">
                  <div @click="tologin">已有账号登录</div>
                </div>
              </div>
            </div>
            <div v-show="showCompany" class="registerr">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="公司名称" prop="orgName">
                  <el-input
                    type="text"
                    v-model="ruleForm.orgName"
                    placeholder="请输入公司名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="公司税号" prop="companyTaxNumber">
                  <el-input
                    type="text"
                    v-model="ruleForm.companyTaxNumber"
                    placeholder="公司税号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="详细地址" prop="orgAddress">
                  <el-input
                    type="text"
                    v-model="ruleForm.orgAddress"
                    placeholder="详细地址"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="orgPhone">
                  <el-input
                    type="text"
                    v-model="ruleForm.orgPhone"
                    placeholder="联系电话"
                  ></el-input>
                </el-form-item>
                <el-form-item label="电子邮箱" prop="orgEmail">
                  <el-input
                    type="text"
                    v-model="ruleForm.orgEmail"
                    placeholder="电子邮箱"
                  ></el-input>
                </el-form-item>
                <el-form-item label="SRM账号" prop="orgEmail">
                  <el-input
                    type="text"
                    v-model="ruleForm.srmUserName"
                    placeholder="SRM账号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="集团公司" prop="orgEmail">
                  <el-select
                    v-model="ruleForm.company"
                    placeholder="请选择"
                    @focus="companySelect(ruleForm.companyTaxNumber)"
                    class="companyStyle"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <el-button type="primary" class="EnterThe" @click="addregister">
                立即进入
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setTimeout } from "timers";
import {
  getCheckTaxNumber,
  getphone,
  toregister,
  getemail,
  setLogin,
  getUserInfo,
  tosoye,
  isSMS,
  getsource,
  // GetfindCompany,
} from "../../api/user";
// import Bus from "../../utils/vueBus";
export default {
  name: "login",
  props: ["isao"],
  data() {
    return {
      isger: false, // 个人信息is
      userName: "", // 账号
      password: "", // 密码
      setlogin: "", // 登录手机号或邮箱
      setphone: "", // 登录验证码
      systemSource: [], // 系统
      ister: false, // 注册下一步
      isTion: false, // 系统切换
      islogn: false, // 登录切换
      isObervte: false, // 注册切换
      registerDto: {
        // 手机号/邮箱
        phone: "",
        // 验证码
        verificationCode: "",
        // 用户名
        userName:"",
        //  密码
        password: "",
        //  确认密码
        password2: "",
        // 公司名称
        orgName: "",
        // 公司税号
        companyTaxNumber: "",
        // SRM账号
        srmUserName: "",
        groupMark: "",
        companyTaxNumberList:[]
      },
      checkedArray: [],
      services: "",
      LoginAgree: false,
      RegisterAgree: false,
      showCompany: false,
      ruleForm: {
        orgName: "", //公司名称
        companyTaxNumber: "", //公司税号
        orgAddress: "", //公司地址
        orgPhone: "", //详细地址
        orgEmail: "", // 电子邮箱
        srmUserName: "", //srmUserName
        company: "", //集团公司
      },
      ifShow: false,
      countdown: true,
      timing: 60,
      // SMSLogin: true,
      // SMSLoginTime: 60,
      phoneReg: /^[1][0-9][0-9]{9}$/, // 手机号正则
      rules: {
        orgName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        companyTaxNumber: [
          { required: true, message: "请输入公司税号", trigger: "blur" },
        ],
      },
      copanyLists: "",
      urlList: [],
      options: [],
      watchIfAgree: window.localStorage.getItem("ifAgree"),
    };
  },
  async created() {
    const result = await getsource();
    // console.log(result);
    this.copanyLists = result.data.result;
    this.services = result.data.result;
    console.error(this.services);
    console.log(this.services);
    // console.log(result.data.result);

    // console.log(this.copanyLists);
    this.urlList = {
      [result.data.result[0].sysSourceEname]: result.data.result[0].ipUrl,
      [result.data.result[1].sysSourceEname]: result.data.result[1].ipUrl,
      [result.data.result[2].sysSourceEname]: result.data.result[2].ipUrl,
      [result.data.result[3].sysSourceEname]: result.data.result[3].ipUrl,
    };
  },
  mounted() {
    this.sing();
    if (localStorage.homeToken) this.getinfo();
    window.addEventListener("storage", (event) => {
      console.log(event);
      if (event.key === "ifAgree" && event.newValue == "true") {
        console.log("ok");
        this.RegisterAgree = true;
      } else {
        this.RegisterAgree = false;
      }
    });
  },

  methods: {
    checkedServices() {
      console.log(this.checkedArray);
      if (this.checkedArray.includes("SRS")) {
        this.ifShow = true;
      } else {
        this.ifShow = false;
      }
    },
    // 判断是否阅读完
    IfAgree() {
      // console.log(this.watchIfAgree);
      console.log(window.localStorage.getItem("ifAgree"));
      console.log(this.RegisterAgree);
      let watchIfAgree = window.localStorage.getItem("ifAgree");

      if (watchIfAgree == "false" || watchIfAgree == null) {
        this.RegisterAgree = false;
        this.toService();
      } else if (this.RegisterAgree == true && watchIfAgree == "true") {
        this.RegisterAgree = true;
      }

      // if (
      //   (this.RegisterAgree == true && watchIfAgree == "false") ||
      //   (this.RegisterAgree == true && watchIfAgree == null)
      // ) {
      //   this.RegisterAgree = false;
      //   this.toService();
      // } else if (this.RegisterAgree == true && watchIfAgree == "true") {
      //   this.RegisterAgree = true;
      // }
    },
    IfLoginAgree() {
      // console.log("LoginAgree", this.LoginAgree);
    },
    // is个人
    onger() {
      let ger = this.isger;
      this.isger = !ger;
    },
    // 跳转到注册
    sing() {
      // window.console.log(this.isObervte)
      if (this.isao == false) {
        this.isObervte = true;
      }
    },
    // 登录
    async toye() {
      if (!this.userName) {
        return this.$message.warning(`请先输入手机号或邮箱!`);
      } else {
        if (this.userName.indexOf("@") !== -1) {
          // console.log(`输入为邮箱`);
        } else {
          if (!this.phoneReg.test(this.userName)) {
            return this.$message.warning(`请输入合法手机号!`);
          }
        }
      }
      if (!this.password) {
        return this.$message.warning(`请先输入密码!`);
      }
      if (this.LoginAgree == false && this.isObervte == true) {
        return this.$message.warning("请勾选服务");
      }
      let userName = this.userName;
      let password = this.password;
      const result = await tosoye({ userName, password });
      console.warn(result);
      console.log(result);
      if (result.data.code == 0) {
        console.log(result);
        localStorage.setItem("homeToken", result.data.result.access_token);
        console.warn(localStorage);
        console.log(localStorage);
        // 获取用户信息
        this.getinfo();

        // this.userName=''
        // this.password = "";
      } else {
        return this.$message.error(result.data.message);
      }
    },
    // 跳转到首页
    todom() {
      // this.$emit('onShow')
      this.$router.back();
    },
    // 获取用户信息
    async getinfo() {
      let token = localStorage.getItem("homeToken");
      console.log(token);
      if (!token) {
        return;
      } else {
        const result = await getUserInfo();
        console.log(result);
        if (result.data.code == 42010003) {
          this.isObervte = true;
          this.isTion = true;
          this.ister = false;
          return;
        }
        //  this.todom()
        let systemSource = result.data.result.systemSource;
        // console.log(systemSource);
        // let ipUrl = result.data.result.ipUrl; // 后续后端添加参数
        this.$router.push({
          path: "/echas",
          query: { systemSource: systemSource, ipUrl: this.urlList },
        });
      }
    },
    // 验证码登录
    async yanlogin() {
      if (!this.setlogin) {
        return this.$message.warning(`请先输入手机号!`);
      } else {
        if (!this.phoneReg.test(this.setlogin)) {
          return this.$message.warning(`请输入合法手机号!`);
        }
      }
      if (!this.setphone) {
        return this.$message.warning(`请先输入验证码!`);
      }
      if (this.LoginAgree == false) {
        return this.$message.warning("请勾选服务");
      }
      let setlogin = this.setlogin;
      let setphone = this.setphone;
      const result = await setLogin(setlogin, setphone);
      // console.log(result);
      if (result.data.code == 0) {
        localStorage.setItem("homeToken", result.data.result.access_token);
        // 获取用户信息
        this.getinfo();
        //  this.setlogin
        this.setphone = "";
      } else {
        // this.setlogin = ''
        // this.setphone = ''
        // this.LoginAgree = false;
        return this.$message.error(result.data.message);
      }
    },
    // 封装手机邮箱验证
    PhoneValidation(nonull) {
      if (!nonull) {
        return this.$message.warning(`请先输入正确手机号或邮箱!`);
      } else {
        let phone = nonull;
        // console.log(phone);
        if (phone.indexOf("@") !== -1) {
          this.toEmail(phone);
          this.showTime();
        } else {
          // 3.如果为手机号则进行正则判断
          if (!this.phoneReg.test(phone)) {
            return this.$message.warning(`请先输入正确手机号!`);
          } else {
            this.getPhone(phone);
            this.showTime();
          }
        }
      }
    },
    // 登录获取验证码
    stphone() {
      this.PhoneValidation(this.setlogin);
    },
    // 发送验证码
    tophone() {
      this.PhoneValidation(this.registerDto.phone);
    },
    // 验证码倒计时 rj
    showTime() {
      // console.log("time");
      this.countdown = false;
      let i = 60;
      let timer = setInterval(() => {
        this.timing = i;
        i--;
        if (i < 0) {
          this.countdown = true;
          clearInterval(timer);
        }
      }, 1000);
    },
    // 获取系统
    async getsource() {
      this.addregister(); 
    },
    // 获取手机验证码
    async getPhone(params) {
      const result = await getphone({
        phone: params,
      });
      // console.log(result.data);
      this.$message.success(result.data.message);
    },
    // 获取邮箱验证码
    async toEmail(params) {
      const result = await getemail(params);
      console.log(result);
      // this.$message.success(result.data.message);
    },

    //  跳转到登陆
    tologin() {
      this.isObervte = false;
      this.islogn = false;
      this.isTion = false;
    },
    // 注册完成
    async addregister() {
      console.log(this.registerDto);
      console.log(this.options);
      console.log(this.registerDto.companyTaxNumberList);
      let watchIfAgree = window.localStorage.getItem("ifAgree");
      if (!this.registerDto.phone) {
        return this.$message.warning(`请输入手机号或邮箱!`);
      } else {
        if (this.registerDto.phone.indexOf("@") !== -1) {
          // console.log(`输入为邮箱`);
        } else {
          if (!this.phoneReg.test(this.registerDto.phone)) {
            return this.$message.warning(`请输入合法手机号!`);
          }
        }
      }
      // || this.registerDto.verificationCode.length !== 6
      if (!this.registerDto.verificationCode) {
        return this.$message.warning(`请输入验证码!`);
      }
      if (!this.registerDto.password) {
        return this.$message.warning(`请输入密码!`);
      }
      if (!this.registerDto.password2) {
        return this.$message.warning(`请再次确认密码!`);
      }
      if (this.registerDto.password !== this.registerDto.password2) {
        return this.$message.warning(`两次输入密码不一致!`);
      }
      if (!this.registerDto.orgName) {
        return this.$message.warning(`请输入公司名称!`);
      }
      if (!this.registerDto.companyTaxNumber) {
        return this.$message.warning(`请输入公司税号!`);
      }
      // if (!this.registerDto.srmUserName) {
      //   return this.$message.warning(`请输入SRM账号!`);
      // }
      // if (!this.registerDto.groupMark) {
      //   return this.$message.warning(`请选择集团与公司!`);
      // }
      if (watchIfAgree == "false" || watchIfAgree == null) {
        this.toService();
      }
      if (this.RegisterAgree == false) {
        return this.$message.warning(`请勾选同意《用户注册服务协议》!`);
      }
      let verificationCode = this.registerDto.verificationCode;
      let orgEmail = this.registerDto.phone;
      let userName = this.registerDto.userName;

      const result = await isSMS({ verificationCode, userName ,orgEmail});
      // 判断验证码是否正确
      if (result.data.code == 0) {
        // this.showCompany = true;
        // return;
      } else {
        return this.$message.warning(result.data.message);
      }
      // this.registerDto.userName = userName;
      this.registerDto.systemSource = this.checkedArray;
      let resultOk = await toregister(this.registerDto);
      console.log(resultOk);
      if (resultOk.data.code == 0) {
        this.$message.success(`注册成功,即将跳转到登入页面!`);
        setTimeout(() => {
          this.showCompany = false;
          this.tologin();
        }, 2000);
      } else {
        this.$message.error(resultOk.data.message);
      }
    },

    // if (resultOk.data.code == 0) {
    //   this.$message.success(`注册成功,即将跳转到登入页面!`);
    //   setTimeout(() => {
    //     this.showCompany = false;
    //     this.tologin();
    //   }, 2000);
    // } else {
    //   this.$message.error(resultOk.data.message);
    // }
    // let registerDto = {
    //   userName: this.registerDto.phone, //手机号
    //   verificationCode: this.registerDto.verificationCode, // 验证码
    //   password: this.registerDto.password, //密码
    //   systemSource: this.checkedArray, // 选择服务
    //   orgName: this.ruleForm.orgName, //公司名称
    //   companyTaxNumber: this.ruleForm.companyTaxNumber, //公司税号
    //   orgAddress: this.ruleForm.orgAddress, //公司地址
    //   // 占位 公司电话
    //   orgEmail: this.ruleForm.orgEmail, // 公司电子邮箱
    //   srmUserName: this.ruleForm.srmUserName, //srmUserName
    //   groupMark: this.ruleForm.company, //公司
    // };
    // // console.log(registerDto);
    // const result = await toregister(registerDto);
    // // console.log(result);
    // if (result.data.code == 0) {
    //   this.$message.success(`注册成功,即将跳转到登入页面!`);
    //   setTimeout(() => {
    //     this.showCompany = false;
    //     this.tologin();
    //   }, 2000);
    // } else {
    //   this.$message.error(result.data.message);
    // }
    //  注册
    async register() {
      if (!this.registerDto.phone) {
        return this.$message.warning(`请输入手机号或邮箱!`);
      } else {
        if (this.registerDto.phone.indexOf("@") !== -1) {
          // console.log(`输入为邮箱`);
        } else {
          if (!this.phoneReg.test(this.registerDto.phone)) {
            return this.$message.warning(`请输入合法手机号!`);
          }
        }
      }
      // || this.registerDto.verificationCode.length !== 6
      if (!this.registerDto.verificationCode) {
        return this.$message.warning(`请输入验证码!`);
      }
      if (!this.registerDto.password) {
        return this.$message.warning(`请输入密码!`);
      }
      if (!this.registerDto.password2) {
        return this.$message.warning(`请再次确认密码!`);
      }
      if (this.registerDto.password !== this.registerDto.password2) {
        return this.$message.warning(`两次输入密码不一致!`);
      }
      if (this.RegisterAgree == false) {
        return this.$message.warning(`请勾选同意《用户注册服务协议》!`);
      }
      let verificationCode = this.registerDto.verificationCode;
      let userName = this.registerDto.userName;
      const result = await isSMS({ verificationCode, userName });
      if (result.data.code == 0) {
        this.showCompany = true;
        return;
      } else {
        return this.$message.warning(result.data.message);
      }
    },
    // async companySelect(e) {
    //   if (e) {
    //     const result = await GetfindCompany({ taxNo: e });
    //     console.log(result);
    //     const list = [];
    //     result.data.result.forEach((item, index) => {
    //       list[index] = {
    //         label: item.orgName,
    //         value: item.groupMark,
    //       };
    //     });
    //     this.options = list;
    //   } else {
    //     return this.$message.warning(`请先输入正确的公司税号!`);
    //   }
    // },
    async companySelect(e) {
      if (e) {
        const result = await getCheckTaxNumber({ taxNo: e });
        this.options = [result.data.result];
        console.log(this.options);
      } else {
        return this.$message.warning(`请先输入正确的公司税号!`);
      }
    },
    toService() {
      // this.$router.push("/serviceAgreement");
      let routeUrl = this.$router.resolve({
        path: "/serviceAgreement",
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
@import url(./css/index.less);
</style>
<style lang="less">
@import url(./css/element-ui-reset.less);
</style>
<style lang="less">
.companyStyle {
  width: 100%;
  .el-select__input {
    margin-left: 0px;
    font-size: 16px;
    font-weight: initial;
    color: #000;
  }
  .el-input__inner {
    border: none;
    padding-left: 2px;
  }
  // .companyStyle {
  //   width: 100%;
  //   .el-input--suffix {
  //     .el-input__inner {
  //       border: none;
  //       padding-left: 2px;
  //       font-size: 16px;
  //       font-weight: initial;
  //     }
  //   }
  // div {
  //   margin-right: 45px;
  // }
}
</style>
