import axios from "axios";
import {
    Message
} from "element-ui";
// import router from "./router";
// import qs from 'qs';
// import store from '@/store';
// import { setStore } from '@/utils/store';


// 创建axios实例
const service = axios.create({
    // api的base_urlhttp://39.104.87.53:6880/gatewayhttp://39.104.87.53:7892]
    baseURL: `https://www.huifai.com/gateway`,
    // baseURL: `http://39.104.87.53:6880/gateway`, // 本地-后端解决跨域后
    // baseURL: `http://39.104.63.189:6882/apGateway`, // 本地-后端解决跨域后
    // baseURL: `http://180.184.65.117:5880/apGateway`, // 本地-后端解决跨域后
    //   baseURL: process.env.NODE_ENV === "dev" ? "/api" : process.env.VUE_APP_URL, // 本地-前端解决跨域
    timeout: 15 * 1000, // 请求超时时间
});

// request 请求拦截器
service.interceptors.request.use(
    // console.log('config'),
    config => {
        console.log('config');
        //设置header
        config.headers["SYSTEMSOURCE"] = "AP";
        // 让每个请求携带自定义token 
        // header添加token
        config.headers["LOGINTOKENKEY"] = localStorage.getItem('homeToken') ? localStorage.getItem('homeToken') : ''
        return config;
    },
    error => {
        console.log('error');
        return Promise.reject(error);
    }
);
// respone 响应拦截器
service.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status == 400) {
            Message({
                message: "参数信息有误",
                center: true
            });
            return;
        } else if (error.response.status == 302) {
            Message({
                message: "用户未登录",
                center: true
            });
            // router.push("/login");
            localStorage.setItem('homeToken', '');
            // localStorage.getItem('homeToken')({ name: 'TOKEN', content: '', type: 'session' })
            return;
        } else if (error.response.status == 404) {
            Message({
                message: "连接失败",
                center: true
            });
            return;
        } else if (error.response.status == 500) {
            Message({
                message: "服务器内部错误",
                center: true
            });
            return;
        } else if (error.response.status == 560) {
            Message({
                message: "数据库异常",
                center: true
            });
            return;
        }
        Message({
            message: error.message,
            type: "error",
            duration: 5 * 1000
        });
        return Promise.reject(error);
    }
);

export default service;